/*------------------------------------*\
  #COMPONENTS - VALIDAÇÃO CERTIFICADO
\*------------------------------------*/

.block-views-exp-validacao-certificado-page {
  label { display: $n; }
  
  .views-exposed-widget { padding: 0; }
  .views-exposed-widget .form-submit { margin-top: 0; }
  
  .views-exposed-widgets {
    display: flex;
    margin-bottom: 0;
  }
  
  form, 
  .views-widget-filter-field_arti_certification_value { width: 100%; }
  
  .form-text {
    color: var(--cl-clean);
    padding-left: var(--sp-half);
    background-color: $t;
    
    &:focus {
      color: var(--cl-secondary);
      background-color: var(--cl-clean);
    }
  }
  
  .form-submit {
    text-transform: uppercase;
    color: var(--cl-secondary);
    background-color: var(--cl-secondary-light);
    border-color: $t;
    
    @include hover {
      color: var(--cl-tertiary);
    }
  }
  
  &__title {
    text-transform: uppercase;
    margin-bottom: var(--sp-half);
  }
  
  @include media('screen', '>medium') {
    display: flex;
    align-items: center;
    
    &__title {
      max-width: em(162px);
      margin-right: var(--sp-unit);
      margin-bottom: 0px;
    }
  }
}
