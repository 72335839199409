/*------------------------------------*\
  #COMPONENTS - EDITION TEASER
\*------------------------------------*/

.c-edition-teaser {
  
  &__label { opacity: .9; }
  
  &__number, 
  &__year { margin: 0; }
  
  &__number {
    line-height: .5;
    font-size: var(--fz-hl);
    margin-top: em(12px);
    
    > a {
      display: block;
      
      &::before {
        content: '';
        display: inline-block;
        @include size(0, 2px);
        background-color: $cc;
        @include transition(all $duration-quickly ease-in-out);
      }
      
      @include hover {
        text-decoration: $n;
        
        &::before { width: var(--sp-and-half); }
      }
    }
  }
  
  &__year {
    line-height: $line-height-reset;
    color: var(--cl-grey-50);
    font-size: var(--fz-m);
    margin-left: var(--sp-quarter);
  }
  
  &__edition { margin-bottom: var(--sp-quarter); }
  
  &__date {
    display: inline-flex;
    align-items: baseline;
  }
  
  @include media('screen', '>medium') {
    &__number { font-size: var(--fz-hhhl); }
  }
}


.o-editions-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--sp-unit);
  
  &--item {
    width: 50%;
    margin-bottom: var(--sp-double);
  }
  
  &--block &--item { margin-bottom: var(--sp-double); }
  
  &--block { margin-bottom: 0; }

  @include media('screen', '>medium') {
    .o-editions-list--page &--item { width: 33.3%; }
  }
}


.block-views-journals-editions-block-1 {
  &__title {
    text-transform: uppercase;
    font-weight: 700;
    color: var(--cl-primary);
    font-size: var(--fz-xm);
    margin-bottom: var(--sp-unit);
  }
}
