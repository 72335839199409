/*------------------------------------*\
  #COMPONENTS - KNOWLEDGE AREAS
\*------------------------------------*/

.block-menu-menu-knowledge-areas {
  margin-top: em(100px);
  margin-bottom: var(--sp-double);
  position: relative;
  z-index: 5;
  
  &__title {
    color: var(--cl-primary);
    margin-bottom: var(--sp-unit);
    max-width: em(120px);
  }
  
  .menu-area-link {
    @include horizontal;
    font-weight: 300;
    font-size: var(--fz-xm);
    padding: var(--sp-quarter) 0;
    margin-bottom: var(--sp-quarter);

    &::before {
      content: '';
      display: inline-block;
      @include size(1rem);
      background: url('#{$imgPath}/arrow-right-brown.svg') 0 center no-repeat;
    }
  }
}



body.o-areas-modal-active {
  overflow: hidden;
  @include size(100%);
}

.o-areas .o-modal-basic__title {
  color: var(--cl-primary);
  margin-bottom: var(--sp-unit);
}

.o-areas-receive {
  .is-expanded > .menu__link {
    font-size: var(--fz-xxm);
    
    &::before {
      content: '';
      display: inline-block;
      @include size(16px, 2px);
      margin-right: em(4px);
      background-color: $cc;
      opacity: .6;
    }
  }
  
  .is-expanded.expanded > .menu__link { color: var(--cl-primary); }
  
  .is-expanded.expanded .dhtml-menu-cloned-leaf {
    width: 100%;
    font-size: 1rem;
    font-style: normal;
  }
  
  .is-expanded.expanded .dhtml-menu-cloned-leaf, 
  .is-leaf { @include transition(all $duration-quickly ease-in-out); }
  
  .is-expanded.expanded .dhtml-menu-cloned-leaf::before, 
  .is-leaf::before {
    content: '';
    display: inline-block;
    @include size(1rem);
    min-width: 1rem;
    margin-top: em(2px);
    background: url('#{$imgPath}/arrow-right-brown.svg') 0 center no-repeat;
  }
  
  .is-expanded.expanded .dhtml-menu-cloned-leaf:hover::before, 
  .is-leaf:hover::before { background-position: center; }
  
  .is-expanded.expanded > .menu {
    display: flex $i;
    flex-wrap: wrap;
    padding-top: em(16px);
  }
  
  .is-expanded {
    width: 100%;
    margin-top: var(--sp-double);
  }
  
  .is-leaf {
    width: 100%;
    margin-top: em(12px);
  }
  
  & > .menu > .menu__item { margin-top: 0; }
  & > .menu > .menu__item > .menu { padding-top: 0; }
  & > .menu > .menu__item > .menu > .dhtml-menu-cloned-leaf::before { display: none; }
  
  @include media('screen', '>medium') {
    .is-leaf {
      display: flex;
      width: 33.33%;
      padding-right: em(16px);
    }
  }
}
