/*------------------------------------*\
  #COMPONENTS - NAVIGATION RWD
\*------------------------------------*/

body.nav-active {
  position: fixed;
  overflow: hidden;
}

.c-rwd-nav {
  display: $n;
  overflow-y: auto;
  @include size(100vw, 100vh);
  padding: var(--sp-unit);
  background-color: rgba(255, 255, 255, .98);
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;

  &__close {
    color: var(--cl-clean);
    font-size: var(--fz-xl);
    background-color: var(--cl-secondary);
    line-height: .7;
    padding: em(12px) em(14px) em(8px);
    position: absolute;
    top: em(32px);
    right: 0;

    @include hover {
      text-decoration: $n;
    }
  }

  &.active { display: block; }
  .c-logo { margin: 0 auto em(24px); }
  .menu__item { margin-bottom: var(--sp-half); }

  #search-rwd {
    border-bottom: 1px solid var(--cl-secondary-light);
    padding-bottom: var(--sp-double);
    margin-bottom: var(--sp-double);
  }

  #m-first { margin-bottom: var(--sp-double); }
}
