/*------------------------------------*\
  #COMPONENTS - USER FIXED
\*------------------------------------*/

// block only section user logged in
// show informations the user in the pages this section

$w-img-user: em(100px);
$w-img-user--half: em(50px);


.c-user-fixed {
  position: relative;
  
  h3, 
  dl, 
  .field-name-realname, 
  .field-name-field-user-picture, 
  .field-name-field-user-citation-name .field-label { display: $n; }
  
  &::before {
    content: '';
    @include size($w-img-user);
    background: $t url('#{$imgPath}/user-icon.svg') center no-repeat;
    background-color: var(--cl-primary);
    background-size: 50%;
    position: absolute;
    top: 0;
    left: -($w-img-user--half);
  }
  
  .profile {
    margin: 0;
    padding-left: var(--sp-quad);
  }
  
  .field-name-field-user-name {
    font-family: $roboto;
    font-weight: 300;
    line-height: $line-height-reset;
    color: var(--cl-primary);
    font-size: var(--fz-hl);
  }
  
  .field-name-field-user-citation-name {
    margin-bottom: 0 $i;
    
    .field-items {
      font-weight: 700;
      color: var(--cl-primary);
      font-size: var(--fz-xm);
    }
  }
}
