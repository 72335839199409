/*------------------------------------*\
  #COMPONENTS - PRODUCT TEASER
\*------------------------------------*/

.o-product-list {
  margin-bottom: var(--sp-unit);
  
  &--item { margin-bottom: var(--sp-double); }

  &--edition, 
  &--edition &--item:last-child { margin-bottom: 0; }
}


// used in the search
.c-product-teaser {
  &__type {
    display: inline-flex;
    align-items: flex-end;
    @include label;
    color: var(--cl-primary);
    line-height: .5;
    
    &::before {
      content: '';
      height: em(2px);
      width: var(--sp-and-half);
      margin-right: var(--sp-half);
      background-color: $cc;
    }
  }
  
  &__area::before { content: '| '; }
  
  &__title {
    font-size: em(18px);
    font: var(--fz-xm)/$line-height-text $roboto;
    border-bottom: 1px solid var(--cl-secondary-light);
    padding-bottom: var(--sp-half);
    margin-bottom: var(--sp-half);
    margin-top: var(--sp-half);
  }
  
  &__writer {
    font-weight: 300;
    font-size: var(--fz-s);
    line-height: $line-height-text;
    
    span { font-weight: 700; }
  }
  
  &__revision { font-weight: 400; }
  
  &__status {
    display: flex;
    font-weight: 400;
  }
}
