/*------------------------------------*\
  #COMPONENTS - TOBOGGAN
\*------------------------------------*/

.toboggan-unified {
  #login-message { display: $n; }
  
  .login-link {
    text-transform: uppercase;
    font-weight: 700;
    width: 50%;
    text-align: center;
    padding: var(--sp-unit);
    
    @include hover {
      text-decoration: $n;
      color: var(--cl-clean);
      background-color: var(--cl-secondary);
    }
  }
  
  .lt-active {
    color: var(--cl-clean);
    background-color: var(--cl-primary);
  }
  
  #login-links {
    display: flex;
    align-items: stretch;
    border: 2px solid var(--cl-primary);
    margin-bottom: var(--sp-double);
  }
}

.page-toboggan-denied .o-main__read { margin-bottom: 0; }
