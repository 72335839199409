///*----------------------------------*\
//  #UTILITIES
//\*----------------------------------*/

.u-ttu { text-transform: uppercase; }
.u-dn { display: none; }
.u-db { display: block; }
.u-dib { display: inline-block; }
.u-fwb { font-weight: 700; }
.u-fllf { float: left; }
.u-flrg { float: right; }

.u-radius-circle { border-radius: $border-radius-circle; }
.u-no-link { @include no-link; }
.u-mobile-scroll-horizontal { @include mobile-scroll-horizontal; }

.u-clear-list { @include clear-list; }
.u-display-label { @include label; }
.u-horizontal { @include horizontal; }
.u-list-horizontal { @include list-horizontal; }
.u-transition-basic { @include transition(all $duration-quickly ease-in-out); }
.u-flexbox-centering { @include flexbox-centering; }