@charset "UTF-8";
:root {
  --cl-clean: #fff;
  --cl-dark: #000;
  --cl-text: #4a4a4f;
  --cl-link-visited: #847A5A;
  --cl-grey-10: #f9f9fa;
  --cl-grey-20: #eeeef1;
  --cl-grey-30: #d7d7db;
  --cl-grey-40: #b2b2b3;
  --cl-grey-50: #737373;
  --cl-grey-60: #4a4a4f;
  --cl-grey-70: #38383d;
  --cl-grey-80: #29292e;
  --cl-grey-90: #0c0c0d;
  --cl-sucess: #13bd00;
  --cl-info: #0a85ff;
  --cl-warning: #d66f00;
  --cl-error: #d60020;
  --cl-primary: #97ab21;
  --cl-secondary: #70653e;
  --cl-tertiary: #e77d04;
  --cl-primary-dark: #687817;
  --cl-secondary-light: #f0efec;
  --cl-secondary-xlight: #f9f9fa;
  --fz-uul: 4.5rem;
  --fz-ul: 4rem;
  --fz-hhhl: 3rem;
  --fz-hhl: 2.75rem;
  --fz-hl: 2.25rem;
  --fz-xxl: 2rem;
  --fz-xl: 1.75rem;
  --fz-l: 1.5rem;
  --fz-xxm: 1.25rem;
  --fz-xm: 1.125rem;
  --fz-m: 16px;
  --fz-s: 0.875rem;
  --fz-xs: 0.75rem;
  --fz-xxs: 0.625rem;
  --sp-quarter: 0.25rem;
  --sp-half: 0.5rem;
  --sp-unit: 1rem;
  --sp-and-half: 1.5rem;
  --sp-double: 2rem;
  --sp-triple: 3rem;
  --sp-quad: 4rem;
}

* {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  overflow-x: hidden;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  font-family: "Work Sans", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 400;
  color: var(--cl-text);
  background-color: var(--cl-clean);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

article {
  font-weight: 300;
  letter-spacing: 0.01875rem;
}

[role="button"] {
  cursor: pointer;
}

::-moz-selection {
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
  text-shadow: none;
}

::selection {
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
  text-shadow: none;
}

::placeholder {
  color: var(--cl-secondary);
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  text-decoration: none;
  color: var(--cl-secondary);
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: var(--cl-link-visited);
}

a:hover,
a:focus {
  text-decoration: underline;
}

a:active {
  color: var(--cl-secondary);
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.h1 {
  margin: 0;
}

article h1, article
.h1 {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2,
.h2 {
  margin: 0;
}

article h2, article
.h2 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3,
.h3 {
  margin: 0;
}

article h3, article
.h3 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4,
.h4 {
  margin: 0;
}

article h4, article
.h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5,
.h5 {
  margin: 0;
}

article h5, article
.h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6,
.h6 {
  margin: 0;
}

article h6, article
.h6 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: 700;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: var(--cl-text);
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.75rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: 700;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: var(--cl-text);
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.75rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child,
hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0;
  list-style: none;
  list-style-image: none;
}

article menu, article
ol, article
ul {
  padding: 0 0 0 32px;
  list-style: initial;
  list-style-image: initial;
}

[dir="rtl"] article menu, [dir="rtl"] article
ol, [dir="rtl"] article
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 0;
}

article p, article
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
  border-radius: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.c-btn, .login-forgot a, .page-cart-order-complete a.btn, .views-field-edit-delete .form-submit, .line-item-summary-view-cart a,
.line-item-summary-checkout a, .checkout-buttons .checkout-cancel, .field-name-field-arti-file a,
.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-flex;
  align-items: center;
  font: 700 1rem/1 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  letter-spacing: 0.01875rem;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: var(--cl-clean);
  padding: var(--sp-half) var(--sp-unit);
  border: 2px solid var(--cl-secondary);
  background-color: var(--cl-secondary);
  height: 3.125rem;
  margin: 0;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.c-btn::-moz-focus-inner, .login-forgot a::-moz-focus-inner, .page-cart-order-complete a.btn::-moz-focus-inner, .views-field-edit-delete .form-submit::-moz-focus-inner, .line-item-summary-view-cart a::-moz-focus-inner,
.line-item-summary-checkout a::-moz-focus-inner, .checkout-buttons .checkout-cancel::-moz-focus-inner, .field-name-field-arti-file a::-moz-focus-inner,
.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.c-btn:-moz-focusring, .login-forgot a:-moz-focusring, .page-cart-order-complete a.btn:-moz-focusring, .views-field-edit-delete .form-submit:-moz-focusring, .line-item-summary-view-cart a:-moz-focusring,
.line-item-summary-checkout a:-moz-focusring, .checkout-buttons .checkout-cancel:-moz-focusring, .field-name-field-arti-file a:-moz-focusring,
.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.c-btn:hover, .login-forgot a:hover, .page-cart-order-complete a.btn:hover, .views-field-edit-delete .form-submit:hover, .line-item-summary-view-cart a:hover,
.line-item-summary-checkout a:hover, .checkout-buttons .checkout-cancel:hover, .field-name-field-arti-file a:hover, .c-btn:active, .login-forgot a:active, .page-cart-order-complete a.btn:active, .views-field-edit-delete .form-submit:active, .line-item-summary-view-cart a:active,
.line-item-summary-checkout a:active, .checkout-buttons .checkout-cancel:active, .field-name-field-arti-file a:active, .c-btn:focus, .login-forgot a:focus, .page-cart-order-complete a.btn:focus, .views-field-edit-delete .form-submit:focus, .line-item-summary-view-cart a:focus,
.line-item-summary-checkout a:focus, .checkout-buttons .checkout-cancel:focus, .field-name-field-arti-file a:focus,
.button:hover,
.button:active,
.button:focus,
button:hover,
button:active,
button:focus,
[type='button']:hover,
[type='button']:active,
[type='button']:focus,
[type='reset']:hover,
[type='reset']:active,
[type='reset']:focus,
[type='submit']:hover,
[type='submit']:active,
[type='submit']:focus {
  text-decoration: none;
  color: var(--cl-clean);
}

.btn-new {
  position: relative;
}

.btn-new::after {
  content: "novo";
  color: var(--cl-clean);
  font-size: var(--fz-xxs);
  background-color: var(--cl-tertiary);
  letter-spacing: 1px;
  padding: 3px 6px;
  border-radius: 2px;
  position: absolute;
  top: -8px;
  right: 8px;
}

.c-btn, .login-forgot a, .page-cart-order-complete a.btn, .views-field-edit-delete .form-submit, .line-item-summary-view-cart a,
.line-item-summary-checkout a, .checkout-buttons .checkout-cancel, .field-name-field-arti-file a {
  text-transform: initial;
}

.c-btn__icon {
  fill: currentColor;
  margin-right: var(--sp-half);
}

.c-btn--large, .line-item-summary-view-cart a,
.line-item-summary-checkout a, .field-name-field-arti-file a {
  padding: var(--sp-unit) var(--sp-and-half);
}

.c-btn--primary, .login-forgot a, #edit-cancel, .line-item-summary-view-cart a,
.line-item-summary-checkout a, .node-article-form #edit-preview,
#edit-field-arti-authors .field-add-more-submit, .view-commerce-cart-form #edit-submit, .checkout-buttons .checkout-cancel, .field-name-field-arti-file a {
  color: var(--cl-secondary);
  border-color: var(--cl-secondary-light);
  background-color: var(--cl-clean);
}

.c-btn--primary:hover, .login-forgot a:hover, #edit-cancel:hover, .line-item-summary-view-cart a:hover,
.line-item-summary-checkout a:hover, .node-article-form #edit-preview:hover,
#edit-field-arti-authors .field-add-more-submit:hover, .view-commerce-cart-form #edit-submit:hover, .checkout-buttons .checkout-cancel:hover, .field-name-field-arti-file a:hover, .c-btn--primary:focus, .login-forgot a:focus, #edit-cancel:focus, .line-item-summary-view-cart a:focus,
.line-item-summary-checkout a:focus, .node-article-form #edit-preview:focus,
#edit-field-arti-authors .field-add-more-submit:focus, .view-commerce-cart-form #edit-submit:focus, .checkout-buttons .checkout-cancel:focus, .field-name-field-arti-file a:focus {
  color: var(--cl-secondary);
  border-color: var(--cl-secondary);
}

.c-btn--primary:active, .login-forgot a:active, #edit-cancel:active, .line-item-summary-view-cart a:active,
.line-item-summary-checkout a:active, .node-article-form #edit-preview:active,
#edit-field-arti-authors .field-add-more-submit:active, .view-commerce-cart-form #edit-submit:active, .checkout-buttons .checkout-cancel:active, .field-name-field-arti-file a:active {
  color: var(--cl-clean);
  border-color: var(--cl-secondary);
  background-color: var(--cl-secondary);
}

.c-btn--cta, .checkout-buttons #edit-continue,
.form-actions #edit-submit:not([value="Buscar"]),
.form-actions #edit-checkout {
  color: var(--cl-clean);
  border-color: var(--cl-tertiary);
  background-color: var(--cl-tertiary);
}

.c-btn--cta:hover, .checkout-buttons #edit-continue:hover,
.form-actions #edit-submit:hover:not([value="Buscar"]),
.form-actions #edit-checkout:hover, .c-btn--cta:active, .checkout-buttons #edit-continue:active,
.form-actions #edit-submit:active:not([value="Buscar"]),
.form-actions #edit-checkout:active, .c-btn--cta:focus, .checkout-buttons #edit-continue:focus,
.form-actions #edit-submit:focus:not([value="Buscar"]),
.form-actions #edit-checkout:focus {
  color: var(--cl-clean);
}

.c-btn--remove, .views-field-edit-delete .form-submit {
  width: 1.75rem;
  height: 1.75rem;
  background-color: var(--cl-clean);
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  text-indent: -100px;
}

.c-btn--remove:hover, .views-field-edit-delete .form-submit:hover, .c-btn--remove:active, .views-field-edit-delete .form-submit:active, .c-btn--remove:focus, .views-field-edit-delete .form-submit:focus {
  background-color: var(--cl-secondary);
}

.c-btn--remove, .views-field-edit-delete .form-submit {
  background-image: url("../images/remove-icon-brown.svg");
}

.c-btn--remove:hover, .views-field-edit-delete .form-submit:hover, .c-btn--remove:active, .views-field-edit-delete .form-submit:active, .c-btn--remove:focus, .views-field-edit-delete .form-submit:focus {
  background-image: url("../images/remove-icon-white.svg");
}

@media screen and (max-width: 992px) {
  .t-btn-rwd, .c-cta-enviar-publicacao .c-btn--cta, .c-cta-enviar-publicacao .checkout-buttons #edit-continue, .checkout-buttons .c-cta-enviar-publicacao #edit-continue,
  .c-cta-enviar-publicacao .form-actions #edit-submit:not([value="Buscar"]), .form-actions .c-cta-enviar-publicacao #edit-submit:not([value="Buscar"]),
  .c-cta-enviar-publicacao .form-actions #edit-checkout, .form-actions .c-cta-enviar-publicacao #edit-checkout, .t-nav-btn,
  .t-btn-login, .t-btn-user {
    width: 3.125rem;
    padding: 0;
    justify-content: center;
  }
  .t-btn-rwd .c-btn__icon, .c-cta-enviar-publicacao .c-btn--cta .c-btn__icon, .c-cta-enviar-publicacao .checkout-buttons #edit-continue .c-btn__icon, .checkout-buttons .c-cta-enviar-publicacao #edit-continue .c-btn__icon, .c-cta-enviar-publicacao .form-actions #edit-submit:not([value="Buscar"]) .c-btn__icon, .form-actions .c-cta-enviar-publicacao #edit-submit:not([value="Buscar"]) .c-btn__icon, .c-cta-enviar-publicacao .form-actions #edit-checkout .c-btn__icon, .form-actions .c-cta-enviar-publicacao #edit-checkout .c-btn__icon, .t-nav-btn .c-btn__icon, .t-btn-login .c-btn__icon, .t-btn-user .c-btn__icon {
    margin-right: 0;
  }
  .t-btn-rwd .c-btn__title, .c-cta-enviar-publicacao .c-btn--cta .c-btn__title, .c-cta-enviar-publicacao .checkout-buttons #edit-continue .c-btn__title, .checkout-buttons .c-cta-enviar-publicacao #edit-continue .c-btn__title, .c-cta-enviar-publicacao .form-actions #edit-submit:not([value="Buscar"]) .c-btn__title, .form-actions .c-cta-enviar-publicacao #edit-submit:not([value="Buscar"]) .c-btn__title, .c-cta-enviar-publicacao .form-actions #edit-checkout .c-btn__title, .form-actions .c-cta-enviar-publicacao #edit-checkout .c-btn__title, .t-nav-btn .c-btn__title, .t-btn-login .c-btn__title, .t-btn-user .c-btn__title {
    display: none;
  }
}

.t-btn-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
  border-color: var(--cl-secondary-light);
  background-color: var(--cl-secondary-light);
  position: relative;
}

.t-btn-cart__icon {
  fill: var(--cl-secondary);
}

.t-btn-cart__qtd {
  font-weight: 700;
  color: var(--cl-clean);
  font-size: var(--fz-xs);
  background-color: var(--cl-secondary);
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  left: -10px;
}

.t-btn-cart:hover {
  border-color: var(--cl-secondary);
}

.t-btn-cart[aria-expanded="true"] {
  border-color: var(--cl-secondary);
  background-color: var(--cl-secondary);
}

.t-btn-cart[aria-expanded="true"] .t-btn-cart__icon {
  fill: var(--cl-clean);
}

.t-btn-cart[aria-expanded="true"] .t-btn-cart__qtd {
  color: var(--cl-secondary);
  background-color: var(--cl-secondary-light);
}

.t-btn-user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.125rem;
}

.t-btn-user .c-btn__icon {
  width: 1.75rem;
}

.t-btn-user .c-btn__title {
  margin-right: var(--sp-half);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 7.75rem;
}

.t-btn-user .field-items {
  display: flex;
  align-items: center;
}

.t-btn-user .field-items::before {
  content: '';
  width: 0;
  height: 0;
  z-index: 2;
  border-left: 6px solid currentColor;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  margin-right: var(--sp-quarter);
}

.t-btn-user[aria-expanded="true"] {
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
}

.t-btn-user[aria-expanded="true"] .c-btn__icon {
  fill: var(--cl-secondary-light);
}

.t-btn-user[aria-expanded="true"] .field-items::before {
  transform: rotate(90deg);
}

.login-forgot a {
  border-color: transparent;
}

.login-forgot a:hover, .login-forgot a:active, .login-forgot a:focus {
  border-color: currentColor;
}

.c-btn[disabled], .login-forgot a[disabled], .page-cart-order-complete a.btn[disabled], .views-field-edit-delete .form-submit[disabled], .line-item-summary-view-cart a[disabled],
.line-item-summary-checkout a[disabled], .checkout-buttons .checkout-cancel[disabled], .field-name-field-arti-file a[disabled],
.button[disabled],
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

.c-btn[disabled]:hover, .login-forgot a[disabled]:hover, .page-cart-order-complete a.btn[disabled]:hover, .views-field-edit-delete .form-submit[disabled]:hover, .line-item-summary-view-cart a[disabled]:hover,
.line-item-summary-checkout a[disabled]:hover, .checkout-buttons .checkout-cancel[disabled]:hover, .field-name-field-arti-file a[disabled]:hover,
.button[disabled]:hover,
button[disabled]:hover,
[disabled]:hover[type='button'],
[disabled]:hover[type='reset'],
[disabled]:hover[type='submit'], .c-btn[disabled]:active, .login-forgot a[disabled]:active, .page-cart-order-complete a.btn[disabled]:active, .views-field-edit-delete .form-submit[disabled]:active, .line-item-summary-view-cart a[disabled]:active,
.line-item-summary-checkout a[disabled]:active, .checkout-buttons .checkout-cancel[disabled]:active, .field-name-field-arti-file a[disabled]:active,
.button[disabled]:active,
button[disabled]:active,
[disabled]:active[type='button'],
[disabled]:active[type='reset'],
[disabled]:active[type='submit'], .c-btn[disabled]:focus, .login-forgot a[disabled]:focus, .page-cart-order-complete a.btn[disabled]:focus, .views-field-edit-delete .form-submit[disabled]:focus, .line-item-summary-view-cart a[disabled]:focus,
.line-item-summary-checkout a[disabled]:focus, .checkout-buttons .checkout-cancel[disabled]:focus, .field-name-field-arti-file a[disabled]:focus,
.button[disabled]:focus,
button[disabled]:focus,
[disabled]:focus[type='button'],
[disabled]:focus[type='reset'],
[disabled]:focus[type='submit'] {
  color: #999999;
}

.views-field-edit-delete .form-submit {
  border: 0;
}

.line-item-summary-view-cart a,
.line-item-summary-checkout a {
  display: block;
  border: 0;
}

.line-item-summary-view-cart a:hover, .line-item-summary-view-cart a:active, .line-item-summary-view-cart a:focus {
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
}

.line-item-summary-checkout a {
  color: var(--cl-tertiary);
}

.line-item-summary-checkout a:hover, .line-item-summary-checkout a:active, .line-item-summary-checkout a:focus {
  color: var(--cl-clean);
  background-color: var(--cl-tertiary);
}

.form-actions {
  display: flex;
  justify-content: space-around;
}

.checkout-buttons #edit-continue,
.form-actions #edit-submit:not([value="Buscar"]),
.form-actions #edit-checkout {
  justify-content: center;
  width: 200px;
}

.checkout-buttons #edit-continue {
  width: auto;
}

[type='text'],
[type='search'],
[type='tel'],
[type='url'],
[type='email'],
[type='password'],
[type='date'],
[type='time'],
[type='number'],
select,
textarea {
  font: 400 1rem/1 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  color: var(--cl-secondary);
  padding: var(--sp-half) var(--sp-unit);
  border: 2px solid var(--cl-secondary-light);
  background-color: var(--cl-clean);
  width: 100%;
  height: 3.125rem;
}

[type='text']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='url']:focus,
[type='email']:focus,
[type='password']:focus,
[type='date']:focus,
[type='time']:focus,
[type='number']:focus,
select:focus,
textarea:focus {
  border-color: var(--cl-secondary);
}

.chosen-container-single .chosen-single {
  display: flex;
  align-items: center;
  font: 400 1rem/1 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  height: 3.125rem;
  border: 2px solid var(--cl-secondary-light);
  background: var(--cl-clean);
  border-radius: 0;
  box-shadow: none;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid var(--cl-grey-20);
  margin: var(--sp-and-half) 0;
}

fieldset:first-child {
  margin-top: 0;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--cl-grey-50);
  font-size: var(--fz-xm);
}

label {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  margin-bottom: var(--sp-half);
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
  display: block;
  min-height: 9.375rem;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

/*------------------------------------*\
  #COMPONENTS - TABLES
\*------------------------------------*/
table {
  background-color: transparent;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}

th {
  text-align: left;
}

.table, table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th, table > thead > tr > th,
.table > thead > tr > td,
table > thead > tr > td,
.table > tbody > tr > th,
table > tbody > tr > th,
.table > tbody > tr > td,
table > tbody > tr > td,
.table > tfoot > tr > th,
table > tfoot > tr > th,
.table > tfoot > tr > td,
table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > thead > tr > th, table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th, table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody, table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table, table .table, .table table, table table {
  background-color: #fff;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd), table > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}

.table > thead > tr > td.active, table > thead > tr > td.active,
.table > thead > tr > th.active,
table > thead > tr > th.active,
.table > thead > tr.active > td,
table > thead > tr.active > td,
.table > thead > tr.active > th,
table > thead > tr.active > th,
.table > tbody > tr > td.active,
table > tbody > tr > td.active,
.table > tbody > tr > th.active,
table > tbody > tr > th.active,
.table > tbody > tr.active > td,
table > tbody > tr.active > td,
.table > tbody > tr.active > th,
table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success, table > thead > tr > td.success,
.table > thead > tr > th.success,
table > thead > tr > th.success,
.table > thead > tr.success > td,
table > thead > tr.success > td,
.table > thead > tr.success > th,
table > thead > tr.success > th,
.table > tbody > tr > td.success,
table > tbody > tr > td.success,
.table > tbody > tr > th.success,
table > tbody > tr > th.success,
.table > tbody > tr.success > td,
table > tbody > tr.success > td,
.table > tbody > tr.success > th,
table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info, table > thead > tr > td.info,
.table > thead > tr > th.info,
table > thead > tr > th.info,
.table > thead > tr.info > td,
table > thead > tr.info > td,
.table > thead > tr.info > th,
table > thead > tr.info > th,
.table > tbody > tr > td.info,
table > tbody > tr > td.info,
.table > tbody > tr > th.info,
table > tbody > tr > th.info,
.table > tbody > tr.info > td,
table > tbody > tr.info > td,
.table > tbody > tr.info > th,
table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning, table > thead > tr > td.warning,
.table > thead > tr > th.warning,
table > thead > tr > th.warning,
.table > thead > tr.warning > td,
table > thead > tr.warning > td,
.table > thead > tr.warning > th,
table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger, table > thead > tr > td.danger,
.table > thead > tr > th.danger,
table > thead > tr > th.danger,
.table > thead > tr.danger > td,
table > thead > tr.danger > td,
.table > thead > tr.danger > th,
table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive, .view-meus-pedidos {
  overflow-x: auto;
  min-height: 0.01%;
}

@media screen and (max-width: 767px) {
  .table-responsive, .view-meus-pedidos {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table, .view-meus-pedidos > .table, .table-responsive > table, .view-meus-pedidos > table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th, .view-meus-pedidos > .table > thead > tr > th, .table-responsive > table > thead > tr > th, .view-meus-pedidos > table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .view-meus-pedidos > .table > thead > tr > td,
  .table-responsive > table > thead > tr > td,
  .view-meus-pedidos > table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .view-meus-pedidos > .table > tbody > tr > th,
  .table-responsive > table > tbody > tr > th,
  .view-meus-pedidos > table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .view-meus-pedidos > .table > tbody > tr > td,
  .table-responsive > table > tbody > tr > td,
  .view-meus-pedidos > table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .view-meus-pedidos > .table > tfoot > tr > th,
  .table-responsive > table > tfoot > tr > th,
  .view-meus-pedidos > table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .view-meus-pedidos > .table > tfoot > tr > td,
  .table-responsive > table > tfoot > tr > td,
  .view-meus-pedidos > table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered, .view-meus-pedidos > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child, .view-meus-pedidos > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .view-meus-pedidos > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .view-meus-pedidos > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .view-meus-pedidos > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .view-meus-pedidos > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child,
  .view-meus-pedidos > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child, .view-meus-pedidos > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .view-meus-pedidos > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .view-meus-pedidos > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .view-meus-pedidos > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .view-meus-pedidos > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child,
  .view-meus-pedidos > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th, .view-meus-pedidos > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .view-meus-pedidos > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .view-meus-pedidos > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .view-meus-pedidos > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested, [dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child,
hr > :first-child {
  margin-top: 1.5rem;
}

h1,
.h1 {
  margin: 0;
}

article h1, article
.h1 {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2,
.h2 {
  margin: 0;
}

article h2, article
.h2 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3,
.h3 {
  margin: 0;
}

article h3, article
.h3 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4,
.h4 {
  margin: 0;
}

article h4, article
.h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5,
.h5 {
  margin: 0;
}

article h5, article
.h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6,
.h6 {
  margin: 0;
}

article h6, article
.h6 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.header:before {
  content: '';
  display: table;
}

.header:after {
  content: '';
  display: table;
  clear: both;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item, [dir="rtl"] .inline.links li, .inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling, [dir="rtl"] .field-label-inline .field-label, .field-label-inline [dir="rtl"] .field-label, [dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *,
.field-label-inline .field-label *,
span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child,
.field-label-inline .field-label + *,
span.field-label + *,
.field-label-inline .field-label + * > :first-child,
span.field-label + * > :first-child,
.field-label-inline .field-label + * > :first-child > :first-child,
span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.field-label {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  margin-bottom: var(--sp-half);
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages, [dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path,
.messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path,
.messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

/*------------------------------------*\
  #COMPONENTS - SEARCH FORM
\*------------------------------------*/
.c-search-form {
  display: flex;
  align-items: center;
  height: 3.125rem;
  border: 0.125rem solid var(--cl-secondary-light);
  background-color: var(--cl-clean);
}

.c-search-form .form-text {
  padding: var(--sp-unit) var(--sp-half);
  border: 0;
  background-color: transparent;
}

.c-search-form .form-submit {
  color: transparent;
  width: 3.125rem;
  height: 3.125rem;
  border: 0;
  background: transparent url("../images/search-icon.svg") center no-repeat;
  overflow: hidden;
  text-indent: -101%;
}

.c-search-form .form-item {
  text-transform: initial;
  width: 100%;
  margin-bottom: 0;
}

.c-search-form .form-actions {
  display: flex;
  align-items: center;
}

.c-search-form .form-actions::before {
  content: '';
  display: inline-block;
  width: 0.125rem;
  height: var(--sp-double);
  background-color: var(--cl-secondary-light);
}

/*------------------------------------*\
  #COMPONENTS - KNOWLEDGE AREAS
\*------------------------------------*/
.block-menu-menu-knowledge-areas {
  margin-top: 6.25rem;
  margin-bottom: var(--sp-double);
  position: relative;
  z-index: 5;
}

.block-menu-menu-knowledge-areas__title {
  color: var(--cl-primary);
  margin-bottom: var(--sp-unit);
  max-width: 7.5rem;
}

.block-menu-menu-knowledge-areas .menu-area-link {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: var(--fz-xm);
  padding: var(--sp-quarter) 0;
  margin-bottom: var(--sp-quarter);
}

.block-menu-menu-knowledge-areas .menu-area-link::before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("../images/arrow-right-brown.svg") 0 center no-repeat;
}

body.o-areas-modal-active {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.o-areas .o-modal-basic__title {
  color: var(--cl-primary);
  margin-bottom: var(--sp-unit);
}

.o-areas-receive .is-expanded > .menu__link {
  font-size: var(--fz-xxm);
}

.o-areas-receive .is-expanded > .menu__link::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 2px;
  margin-right: 0.25rem;
  background-color: currentColor;
  opacity: .6;
}

.o-areas-receive .is-expanded.expanded > .menu__link {
  color: var(--cl-primary);
}

.o-areas-receive .is-expanded.expanded .dhtml-menu-cloned-leaf {
  width: 100%;
  font-size: 1rem;
  font-style: normal;
}

.o-areas-receive .is-expanded.expanded .dhtml-menu-cloned-leaf,
.o-areas-receive .is-leaf {
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.o-areas-receive .is-expanded.expanded .dhtml-menu-cloned-leaf::before,
.o-areas-receive .is-leaf::before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  margin-top: 0.125rem;
  background: url("../images/arrow-right-brown.svg") 0 center no-repeat;
}

.o-areas-receive .is-expanded.expanded .dhtml-menu-cloned-leaf:hover::before,
.o-areas-receive .is-leaf:hover::before {
  background-position: center;
}

.o-areas-receive .is-expanded.expanded > .menu {
  display: flex !important;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.o-areas-receive .is-expanded {
  width: 100%;
  margin-top: var(--sp-double);
}

.o-areas-receive .is-leaf {
  width: 100%;
  margin-top: 0.75rem;
}

.o-areas-receive > .menu > .menu__item {
  margin-top: 0;
}

.o-areas-receive > .menu > .menu__item > .menu {
  padding-top: 0;
}

.o-areas-receive > .menu > .menu__item > .menu > .dhtml-menu-cloned-leaf::before {
  display: none;
}

@media screen and (min-width: 993px) {
  .o-areas-receive .is-leaf {
    display: flex;
    width: 33.33%;
    padding-right: 1rem;
  }
}

/*------------------------------------*\
  #COMPONENTS - AVALIATION-QUALIS
\*------------------------------------*/
.block-views-avaliation-area-qualis-block__title {
  color: var(--cl-primary);
  margin-bottom: var(--sp-unit);
}

.c-avaliation-qualis {
  display: flex;
  border-bottom: 1px solid transparent;
  padding-bottom: var(--sp-half);
  margin-bottom: var(--sp-half);
  border-color: var(--cl-secondary-light);
}

.c-avaliation-qualis__title-sub {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  font-style: normal;
  font-weight: 400;
}

.c-avaliation-qualis__value {
  color: var(--cl-primary-dark);
}

.c-avaliation-qualis__title {
  align-self: center;
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 400;
  font-size: var(--fz-m);
}

.c-avaliation-qualis__qualis {
  line-height: 1;
  margin-left: auto;
  max-width: 96px;
}

.c-avaliation-qualis--page .c-avaliation-qualis__title {
  margin: 0;
  line-height: 1.5;
  font-size: var(--fz-xm);
}

.c-avaliation-qualis--page .c-avaliation-qualis__qualis {
  margin-top: 0;
  margin-bottom: 0;
}

/*------------------------------------*\
  #COMPONENTS - PRODUCT TEASER
\*------------------------------------*/
.o-product-list {
  margin-bottom: var(--sp-unit);
}

.o-product-list--item {
  margin-bottom: var(--sp-double);
}

.o-product-list--edition,
.o-product-list--edition .o-product-list--item:last-child {
  margin-bottom: 0;
}

.c-product-teaser__type {
  display: inline-flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  color: var(--cl-primary);
  line-height: .5;
}

.c-product-teaser__type::before {
  content: '';
  height: 0.125rem;
  width: var(--sp-and-half);
  margin-right: var(--sp-half);
  background-color: currentColor;
}

.c-product-teaser__area::before {
  content: '| ';
}

.c-product-teaser__title {
  font-size: 1.125rem;
  font: var(--fz-xm)/1.3 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  border-bottom: 1px solid var(--cl-secondary-light);
  padding-bottom: var(--sp-half);
  margin-bottom: var(--sp-half);
  margin-top: var(--sp-half);
}

.c-product-teaser__writer {
  font-weight: 300;
  font-size: var(--fz-s);
  line-height: 1.3;
}

.c-product-teaser__writer span {
  font-weight: 700;
}

.c-product-teaser__revision {
  font-weight: 400;
}

.c-product-teaser__status {
  display: flex;
  font-weight: 400;
}

/*------------------------------------*\
  #COMPONENTS - VALIDAÇÃO CERTIFICADO
\*------------------------------------*/
.block-views-exp-validacao-certificado-page label {
  display: none;
}

.block-views-exp-validacao-certificado-page .views-exposed-widget {
  padding: 0;
}

.block-views-exp-validacao-certificado-page .views-exposed-widget .form-submit {
  margin-top: 0;
}

.block-views-exp-validacao-certificado-page .views-exposed-widgets {
  display: flex;
  margin-bottom: 0;
}

.block-views-exp-validacao-certificado-page form,
.block-views-exp-validacao-certificado-page .views-widget-filter-field_arti_certification_value {
  width: 100%;
}

.block-views-exp-validacao-certificado-page .form-text {
  color: var(--cl-clean);
  padding-left: var(--sp-half);
  background-color: transparent;
}

.block-views-exp-validacao-certificado-page .form-text:focus {
  color: var(--cl-secondary);
  background-color: var(--cl-clean);
}

.block-views-exp-validacao-certificado-page .form-submit {
  text-transform: uppercase;
  color: var(--cl-secondary);
  background-color: var(--cl-secondary-light);
  border-color: transparent;
}

.block-views-exp-validacao-certificado-page .form-submit:hover, .block-views-exp-validacao-certificado-page .form-submit:active, .block-views-exp-validacao-certificado-page .form-submit:focus {
  color: var(--cl-tertiary);
}

.block-views-exp-validacao-certificado-page__title {
  text-transform: uppercase;
  margin-bottom: var(--sp-half);
}

@media screen and (min-width: 993px) {
  .block-views-exp-validacao-certificado-page {
    display: flex;
    align-items: center;
  }
  .block-views-exp-validacao-certificado-page__title {
    max-width: 10.125rem;
    margin-right: var(--sp-unit);
    margin-bottom: 0px;
  }
}

/*------------------------------------*\
  #COMPONENTS - TITLE PAGE
\*------------------------------------*/
.c-title-page {
  position: relative;
  color: var(--cl-primary-dark);
  background-color: var(--cl-clean);
  box-shadow: -4px 0 0 var(--cl-clean);
}

.c-title-page__title {
  text-transform: uppercase;
  line-height: 1.2;
  font-size: var(--fz-hl);
  margin: 0;
  padding: 0;
}

.node-type-article .c-title-page__title {
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 300;
}

.c-title-page__line {
  display: block;
  width: 100%;
  height: 0.125rem;
  background-color: currentColor;
  position: absolute;
  top: 28px;
  left: -50%;
  z-index: -1;
}

.node-type-article .c-title-page__line {
  top: 32px;
}

/*------------------------------------*\
  #COMPONENTS - EDITION TEASER
\*------------------------------------*/
.c-edition-teaser__label {
  opacity: .9;
}

.c-edition-teaser__number, .c-edition-teaser__year {
  margin: 0;
}

.c-edition-teaser__number {
  line-height: .5;
  font-size: var(--fz-hl);
  margin-top: 0.75rem;
}

.c-edition-teaser__number > a {
  display: block;
}

.c-edition-teaser__number > a::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: currentColor;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.c-edition-teaser__number > a:hover, .c-edition-teaser__number > a:active, .c-edition-teaser__number > a:focus {
  text-decoration: none;
}

.c-edition-teaser__number > a:hover::before, .c-edition-teaser__number > a:active::before, .c-edition-teaser__number > a:focus::before {
  width: var(--sp-and-half);
}

.c-edition-teaser__year {
  line-height: 1;
  color: var(--cl-grey-50);
  font-size: var(--fz-m);
  margin-left: var(--sp-quarter);
}

.c-edition-teaser__edition {
  margin-bottom: var(--sp-quarter);
}

.c-edition-teaser__date {
  display: inline-flex;
  align-items: baseline;
}

@media screen and (min-width: 993px) {
  .c-edition-teaser__number {
    font-size: var(--fz-hhhl);
  }
}

.o-editions-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--sp-unit);
}

.o-editions-list--item {
  width: 50%;
  margin-bottom: var(--sp-double);
}

.o-editions-list--block .o-editions-list--item {
  margin-bottom: var(--sp-double);
}

.o-editions-list--block {
  margin-bottom: 0;
}

@media screen and (min-width: 993px) {
  .o-editions-list--page .o-editions-list--item {
    width: 33.3%;
  }
}

.block-views-journals-editions-block-1__title {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--cl-primary);
  font-size: var(--fz-xm);
  margin-bottom: var(--sp-unit);
}

/*------------------------------------*\
  #COMPONENTS - PHRASE HIGHLIGHT
\*------------------------------------*/
.c-phrase-highlight {
  font-size: 1.75rem;
  font: 300 var(--fz-xl)/1.2 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  color: var(--cl-primary-dark);
}

@media screen and (min-width: 993px) {
  .c-phrase-highlight {
    font-size: var(--fz-hl);
  }
}

/*------------------------------------*\
  #COMPONENTS - PRODUCT SHARE
\*------------------------------------*/
.share-title {
  grid-area: title;
}

.share-title__text {
  display: none;
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  line-height: 2.9375rem;
  width: calc(100% - 3rem);
  height: 100%;
  padding-left: var(--sp-unit);
  border-right: 2px solid var(--cl-secondary-light);
}

.share-title__icon {
  margin: 0 auto;
  fill: var(--cl-grey-50);
}

@media screen and (min-width: 993px) {
  .share-title__text {
    display: block;
  }
}

.share-in {
  grid-area: icon-in;
}

.share-tw {
  grid-area: icon-tw;
}

.share-fb {
  grid-area: icon-fb;
}

.c-product-share {
  display: grid;
}

.c-product-share dd {
  margin: 0;
}

.c-product-share__link {
  display: flex;
  justify-content: center;
  width: var(--sp-triple);
  height: var(--sp-triple);
  border: 2px solid var(--cl-secondary-light);
  background-color: var(--cl-clean);
}

.c-product-share__link:hover, .c-product-share__link:active, .c-product-share__link:focus {
  border-color: var(--cl-secondary);
}

.c-product-share__title {
  display: flex;
  align-items: center;
  height: var(--sp-triple);
  border: 2px solid var(--cl-secondary-light);
  background-color: var(--cl-clean);
}

.c-product-share__item {
  fill: var(--cl-secondary);
}

@media screen and (max-width: 992px) {
  .c-product-share {
    grid-template-columns: 3rem;
    grid-template-rows: repeat(3, 3rem) auto;
    grid-template-areas: "icon-in" "icon-tw" "icon-fb" "title";
    grid-row-gap: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 993px) {
  .c-product-share {
    grid-template-columns: repeat(3, 3rem) auto;
    grid-template-areas: "icon-in icon-tw icon-fb title";
    grid-column-gap: 0.5rem;
    height: var(--sp-triple);
    margin-bottom: var(--sp-double);
  }
}

/*------------------------------------*\
  #COMPONENTS - ASIDE HIGHLIGHT
\*------------------------------------*/
.c-aside-highlight {
  width: 100%;
  height: 12.5rem;
  color: var(--cl-clean);
  background: var(--cl-primary);
  position: relative;
}

.c-aside-highlight h3 {
  max-width: 35%;
  position: absolute;
  top: var(--sp-and-half);
  left: var(--sp-and-half);
}

.c-aside-highlight img {
  position: absolute;
  right: -15%;
}

/*------------------------------------*\
  #COMPONENTS - USER FIXED
\*------------------------------------*/
.c-user-fixed {
  position: relative;
}

.c-user-fixed h3,
.c-user-fixed dl,
.c-user-fixed .field-name-realname,
.c-user-fixed .field-name-field-user-picture,
.c-user-fixed .field-name-field-user-citation-name .field-label {
  display: none;
}

.c-user-fixed::before {
  content: '';
  width: 6.25rem;
  height: 6.25rem;
  background: transparent url("../images/user-icon.svg") center no-repeat;
  background-color: var(--cl-primary);
  background-size: 50%;
  position: absolute;
  top: 0;
  left: -3.125rem;
}

.c-user-fixed .profile {
  margin: 0;
  padding-left: var(--sp-quad);
}

.c-user-fixed .field-name-field-user-name {
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 300;
  line-height: 1;
  color: var(--cl-primary);
  font-size: var(--fz-hl);
}

.c-user-fixed .field-name-field-user-citation-name {
  margin-bottom: 0 !important;
}

.c-user-fixed .field-name-field-user-citation-name .field-items {
  font-weight: 700;
  color: var(--cl-primary);
  font-size: var(--fz-xm);
}

/*------------------------------------*\
  #COMPONENTS - TOBOGGAN
\*------------------------------------*/
.toboggan-unified #login-message {
  display: none;
}

.toboggan-unified .login-link {
  text-transform: uppercase;
  font-weight: 700;
  width: 50%;
  text-align: center;
  padding: var(--sp-unit);
}

.toboggan-unified .login-link:hover, .toboggan-unified .login-link:active, .toboggan-unified .login-link:focus {
  text-decoration: none;
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
}

.toboggan-unified .lt-active {
  color: var(--cl-clean);
  background-color: var(--cl-primary);
}

.toboggan-unified #login-links {
  display: flex;
  align-items: stretch;
  border: 2px solid var(--cl-primary);
  margin-bottom: var(--sp-double);
}

.page-toboggan-denied .o-main__read {
  margin-bottom: 0;
}

.visually-hidden,
.element-invisible,
.element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.breadcrumb {
  margin-bottom: var(--sp-triple);
}

.breadcrumb__item {
  color: var(--cl-grey-50);
  font-size: var(--fz-s);
}

.breadcrumb__item:not(:first-child) {
  margin-left: var(--sp-half);
}

.breadcrumb__title {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  margin-bottom: var(--sp-quarter);
}

.breadcrumb__list {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .breadcrumb__item {
    white-space: nowrap;
  }
  .breadcrumb__list {
    overflow-x: auto;
  }
}

@media print {
  .breadcrumb {
    display: none;
  }
}

[dir="rtl"] .more-link, [dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon, .more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon, [dir="rtl"] .more-help-link a, .more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.more-link a, .more-help-link a {
  display: flex;
  align-items: center;
}

.more-link a::before, .more-help-link a::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  background: url("../images/arrow-right-brown.svg") 0 center no-repeat;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.more-link a:hover::before, .more-help-link a:hover::before, .more-link a:active::before, .more-help-link a:active::before, .more-link a:focus::before, .more-help-link a:focus::before {
  background-position: center;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

.pager:before {
  content: '';
  display: table;
}

.pager:after {
  content: '';
  display: table;
  clear: both;
}

@media print {
  .pager {
    display: none;
  }
}

.pager-item, .pager-current,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
  font-weight: 700;
  float: left;
}

.pager-first,
.pager-previous,
.pager-next,
.pager-last {
  text-transform: uppercase;
  font-size: var(--fz-s);
}

.pager-item, .pager-current,
.pager-ellipsis {
  color: var(--cl-grey-50);
  font-size: var(--fz-l);
  margin: var(--sp-half) 0;
}

.pager-previous + .pager-item, .pager-previous + .pager-current,
.pager-next {
  clear: left;
}

.pager-current {
  font-weight: bold;
  color: var(--cl-primary);
}

.clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

.tabs:before {
  content: '';
  display: table;
}

.tabs:after {
  content: '';
  display: table;
  clear: both;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

/*------------------------------------*\
  #COMPONENTS - NAVIGATION RWD
\*------------------------------------*/
body.nav-active {
  position: fixed;
  overflow: hidden;
}

.c-rwd-nav {
  display: none;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  padding: var(--sp-unit);
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
}

.c-rwd-nav__close {
  color: var(--cl-clean);
  font-size: var(--fz-xl);
  background-color: var(--cl-secondary);
  line-height: .7;
  padding: 0.75rem 0.875rem 0.5rem;
  position: absolute;
  top: 2rem;
  right: 0;
}

.c-rwd-nav__close:hover, .c-rwd-nav__close:active, .c-rwd-nav__close:focus {
  text-decoration: none;
}

.c-rwd-nav.active {
  display: block;
}

.c-rwd-nav .c-logo {
  margin: 0 auto 1.5rem;
}

.c-rwd-nav .menu__item {
  margin-bottom: var(--sp-half);
}

.c-rwd-nav #search-rwd {
  border-bottom: 1px solid var(--cl-secondary-light);
  padding-bottom: var(--sp-double);
  margin-bottom: var(--sp-double);
}

.c-rwd-nav #m-first {
  margin-bottom: var(--sp-double);
}

.c-menu-institution .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.c-menu-institution .menu {
  display: flex;
  align-items: center;
}

.c-menu-interaction {
  margin-left: auto;
}

.c-menu-interaction .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.c-menu-interaction--footer {
  margin-left: auto;
}

@media screen and (min-width: 993px) {
  .c-menu-interaction .menu {
    display: flex;
    align-items: center;
  }
}

.t-nav-btn .c-btn__icon {
  width: 1.875rem;
}

.rdw-fixed-btns {
  display: none;
}

.rdw-fixed-btns a.t-btn-login {
  margin-top: 0;
}

.rdw-fixed-btns .c-btn--cta, .rdw-fixed-btns .checkout-buttons #edit-continue, .checkout-buttons .rdw-fixed-btns #edit-continue,
.rdw-fixed-btns .form-actions #edit-submit:not([value="Buscar"]), .form-actions .rdw-fixed-btns #edit-submit:not([value="Buscar"]),
.rdw-fixed-btns .form-actions #edit-checkout, .form-actions .rdw-fixed-btns #edit-checkout {
  grid-column: 2;
}

@media screen and (max-width: 992px) {
  .rdw-fixed-btns {
    display: grid;
    grid-template-columns: 50px auto 50px;
    grid-column-gap: 8px;
    width: 100vw;
    padding: var(--sp-half);
    border-top: 1px solid var(--cl-secondary-light);
    background-color: var(--cl-clean);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
  }
}

.region-navigation {
  width: 100%;
  max-width: 81.25rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  display: none;
}

@media screen and (min-width: 768px) {
  .region-navigation {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 1301px) {
  .region-navigation {
    padding: 0;
  }
}

.region-navigation .menu__item {
  margin: 0 var(--sp-unit);
}

.region-navigation .menu__item:first-child {
  margin-left: 0;
}

.region-navigation .menu__item:last-child {
  margin-right: 0;
}

.region-navigation .menu__link {
  display: block;
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  line-height: 1;
  font-size: var(--fz-xm);
  padding: var(--sp-half) 0 var(--sp-unit);
  border-bottom: 3px solid transparent;
  margin-bottom: -2px;
}

.region-navigation .menu__link:hover, .region-navigation .menu__link:active, .region-navigation .menu__link:focus {
  text-decoration: none;
  border-color: var(--cl-secondary);
}

.region-navigation .menu__link.active-trail {
  font-weight: 700;
  color: var(--cl-primary);
  border-color: var(--cl-primary);
}

@media screen and (min-width: 993px) {
  .region-navigation {
    display: flex;
  }
}

.node-type-article .menu__link[href="/publicacoes"],
.section-tag .menu__link[href="/publicacoes"],
.section-area .menu__link[href="/publicacoes"],
.page-publicacoes-edicao .menu__link[href="/edicoes"] {
  font-weight: 700;
  color: var(--cl-primary);
  border-color: var(--cl-primary);
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete, [dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend, [dir="rtl"] .collapsible .fieldset-legend, .collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper,
.is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend,
.is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend, .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend, .collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend, [dir="rtl"] .collapsible.collapsed .fieldset-legend, .collapsible.collapsed [dir="rtl"] .fieldset-legend, [dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend, .collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend, [dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.clearfix:before {
  content: '';
  display: table;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.form-item {
  margin-bottom: var(--sp-unit);
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description,
.fieldset-description {
  font: 400 0.875rem/1.3 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  color: var(--cl-grey-50);
  margin-top: var(--sp-half);
}

.form-item--inline div,
.form-item--inline label,
.container-inline .form-item div,
.container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label,
.form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.form-item--radio .form-item__description, .form-item--radio__description,
.form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description, .form-item--radio .form-item .description, .form-item .form-item--radio .description, .form-item--radio .fieldset-description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item .form-type-checkbox .description,
.form-type-radio .fieldset-description,
.form-type-checkbox .fieldset-description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.fieldset-description {
  margin-top: 0;
  margin-bottom: var(--sp-unit);
}

.views-exposed-form {
  margin-bottom: 2rem;
}

#views-exposed-form-validacao-certificado-page .views-exposed-form {
  margin-bottom: 0;
}

.views-exposed-form .views-exposed-widget .form-submit,
.views-exposed-widgets {
  margin: 0;
}

.views-exposed-form .views-exposed-widget {
  float: initial;
  padding: initial;
  margin-bottom: 0.75rem;
}

.views-exposed-form .views-exposed-widget:last-child {
  margin-bottom: 0;
}

.chosen-container {
  width: 100% !important;
}

.form-file {
  line-height: 2.25rem;
  width: 100%;
  border: 2px solid var(--cl-secondary-light);
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header, [dir="rtl"] .form-table th, .form-table [dir="rtl"] th,
[dir="rtl"] form table th, form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url("../images/grippie.png") no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle, [dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover,
.is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle, .table-drag__handle.is-hover .tabledrag-handle .handle, .tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper, [dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation, [dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child, [dir="rtl"] .table-drag__tree-child-last, [dir="rtl"] .tree-child-last, [dir="rtl"] .table-drag__tree-child-horizontal, [dir="rtl"] .tree-child-horizontal, [dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.o-wrapper {
  width: 100%;
  max-width: 81.25rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .o-wrapper {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 1301px) {
  .o-wrapper {
    padding: 0;
  }
}

.c-logo {
  grid-area: logo;
  display: block;
  max-width: 12.875rem;
}

@media screen and (min-width: 993px) {
  .c-logo {
    max-width: initial;
    width: 14.6875rem;
  }
}

.block-search-form {
  grid-area: search;
  max-width: 40.625rem;
}

@media screen and (min-width: 993px) {
  .logged-in .block-search-form {
    margin-left: var(--sp-double);
    margin-right: var(--sp-double);
  }
}

.c-login-btn {
  grid-area: login;
}

.c-cta-enviar-publicacao {
  grid-area: cta;
}

.c-cart-header {
  grid-area: cart;
}

.c-user-menu {
  grid-area: menu;
}

.o-header {
  margin-top: var(--sp-unit);
  margin-bottom: var(--sp-triple);
  border-bottom: 1px solid var(--cl-secondary-light);
}

.front .o-header {
  margin-bottom: 0;
}

.o-header__top {
  display: grid;
  grid-template-columns: auto 3.125rem;
  grid-template-areas: "logo   logo" "search login";
  column-gap: 0.75rem;
  row-gap: 0.75rem;
  grid-auto-flow: column;
  align-items: start;
  margin-bottom: var(--sp-and-half);
}

.logged-in .o-header__top {
  grid-template-areas: "logo   logo" "search cart";
}

@media screen and (min-width: 993px) {
  .o-header__top {
    grid-template-columns: 14.6875rem auto 13.25rem 7.625rem;
    grid-template-areas: "logo search cta login";
    grid-column-gap: 2rem;
    align-items: center;
  }
  .logged-in .o-header__top {
    grid-template-columns: 14.6875rem auto 15.25rem 3.625rem 13.125rem;
    grid-template-areas: "logo search cta cart menu";
    grid-column-gap: initial;
  }
}

.page-user.not-logged-in .o-header__top {
  grid-template-columns: auto 3.125rem;
  grid-template-areas: "logo login";
  grid-column-gap: initial;
}

@media screen and (min-width: 993px) {
  .page-user.not-logged-in .o-header__top {
    grid-template-columns: 3fr 2fr;
  }
}

@media screen and (max-width: 992px) {
  .c-logo {
    margin: auto;
  }
  .c-cta-enviar-publicacao {
    display: none;
  }
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

.page-search #search-form {
  display: none;
}

table {
  margin: 0 0 var(--sp-and-half);
}

.o-tabs-system {
  padding-top: 1rem !important;
  padding-bottom: 2rem !important;
}

.o-tabs-system .tabs {
  margin: 0;
}

.t-user.o-tabs-system {
  display: none;
}

.logged-in.node-type-article:not(.page-node-edit) .t-user.o-tabs-system,
.logged-in.page-node-edit .t-user.o-tabs-system {
  padding-top: 0 !important;
  display: block;
}

.o-action-links {
  margin-bottom: var(--sp-triple);
}

.view-header h2 {
  margin-top: 0;
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 400;
  color: var(--cl-primary);
  font-size: var(--fz-xxm);
}

.view-empty h1 {
  color: var(--cl-clean);
  font-size: var(--fz-xxm);
  padding: var(--sp-double);
  background-color: var(--cl-tertiary);
  line-height: 1.2;
  margin: 0;
}

.view-articles .view-header,
.view-articles .view-footer {
  display: none;
}

#user-login .form-type-password {
  margin-bottom: 2rem;
}

#user-login .login-forgot {
  float: right;
}

#user-login .form-actions {
  display: inline-flex;
}

#edit-preview-changes {
  display: none;
}

.o-main {
  position: relative;
  margin-bottom: var(--sp-triple);
}

.o-main__header {
  margin-bottom: var(--sp-double);
}

.node-type-article .o-main__header {
  padding-top: var(--sp-unit);
}

@media screen and (min-width: 993px) {
  .o-main {
    margin-bottom: var(--sp-quad);
  }
}

@media screen and (min-width: 993px) {
  .t-two-cols {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 48px;
  }
}

.o-wrapper--read {
  display: grid;
  grid-template-columns: minmax(0.5rem, 1fr) repeat(4, minmax(4rem, 16.25rem)) minmax(0.5rem, 1fr);
  grid-template-areas: "read read      read      read      read      ." ".    aside-one aside-one aside-one aside-one ." ".    aside-two aside-two aside-two aside-two .";
}

.page-journal .o-wrapper--read,
.node-type-article:not(.page-node-edit) .o-wrapper--read {
  grid-template-areas: "read read      read      read      read      read" ".    aside-two aside-two aside-two aside-two .";
}

.page-checkout .o-wrapper--read {
  grid-template-areas: "read read read read read read";
}

@media screen and (min-width: 993px) {
  .o-wrapper--read {
    grid-template-columns: minmax(1rem, 1fr) repeat(5, minmax(4rem, 16.25rem)) minmax(1rem, 1fr);
    grid-template-areas: "read read      read      read      aside-one aside-one ." ".    aside-two aside-two aside-two aside-two aside-two .";
  }
  .node-type-article:not(.page-node-edit) .o-wrapper--read {
    grid-template-areas: "read read      read      read      read      read      read" ".    aside-two aside-two aside-two aside-two aside-two .";
  }
  .o-main--user .o-wrapper--read {
    grid-template-areas: "read read read read aside-one aside-one .";
  }
  .page-checkout .o-wrapper--read {
    grid-template-areas: "read read read read read read read";
  }
}

.o-main__read {
  grid-area: read;
  margin-bottom: var(--sp-triple);
  display: grid;
  grid-template-columns: minmax(0.5rem, 1fr) repeat(4, minmax(4rem, 16.25rem));
  grid-template-rows: auto 100%;
  grid-template-areas: "header header header header header" ".      text   text   text   text";
}

.node-type-article:not(.page-node-edit) .o-main__read {
  grid-template-columns: minmax(0.5rem, 1fr) repeat(4, minmax(4rem, 16.25rem)) minmax(0.5rem, 1fr);
  grid-template-rows: 0.75rem minmax(1rem, auto);
  grid-auto-rows: auto;
  grid-template-areas: "type    type   type   type   type   ." "header  header header header header ." ".       text   text   text   text   ." ".       ref    ref    ref    ref    ref" ".       share  infos  infos  infos  .";
}

.page-checkout .o-main__read {
  grid-template-columns: minmax(0.5rem, 1fr) repeat(4, minmax(4rem, 16.25rem)) minmax(0.5rem, 1fr);
  grid-template-areas: "header header header header header ." ".      text   text   text   text   .";
}

.page-meus-pedidos .o-main__read,
.page-emitir-certificado-online .o-main__read,
.page-cart .o-main__read,
.page-node-add-article .o-main__read {
  margin-bottom: 0;
}

@media screen and (min-width: 993px) {
  .o-main__read {
    grid-template-columns: minmax(1rem, 1fr) repeat(3, minmax(4rem, 16.25rem));
    grid-template-areas: "header header header header" ".      .      text   text";
    margin-bottom: var(--sp-quad);
  }
  .node-type-article:not(.page-node-edit) .o-main__read {
    grid-template-columns: minmax(1rem, 1fr) repeat(5, minmax(4rem, 16.25rem)) minmax(1rem, 1fr);
    grid-template-rows: 0.75rem 3.6875rem minmax(1rem, auto);
    grid-template-areas: "type   type   type   type   share share ." "header header header header share share ." "header header header header infos infos ." ".      .      text   text   infos infos ." ".      ref    ref    ref    ref   ref   ref";
  }
  .o-main--user .o-main__read {
    grid-template-rows: auto 1fr;
    margin-bottom: 0;
  }
  .page-checkout .o-main__read {
    grid-template-columns: minmax(1rem, 1fr) repeat(5, minmax(4rem, 16.25rem)) minmax(1rem, 1fr);
    grid-template-areas: "header header header header .     .     ." ".      text   text   text   text  text  .";
  }
}

.page-toboggan-denied .toboggan-unified,
.view-commerce-cart-form,
.cart-empty-page {
  grid-area: text;
}

@media screen and (min-width: 993px) {
  .page-toboggan-denied .toboggan-unified,
  .view-commerce-cart-form,
  .cart-empty-page {
    grid-column: 2 / span 3;
  }
}

.page-cart-order-complete .o-main__read p {
  grid-column: 2 / span 4;
}

@media screen and (min-width: 993px) {
  .page-cart-order-complete .o-main__read p {
    grid-column: 2 / span 3;
  }
}

.page-cart-order-complete .o-main__read p {
  margin-top: 0;
}

.o-sidebar-first {
  overflow: hidden;
  margin-bottom: var(--sp-triple);
}

@media screen and (max-width: 992px) {
  .o-sidebar-first {
    margin-top: var(--sp-triple);
  }
}

.o-sidebar-second {
  width: 100%;
  margin-top: var(--sp-triple);
}

@media screen and (min-width: 993px) {
  .o-sidebar-second {
    margin-top: var(--sp-quad);
  }
}

#legal-login,
.read-content-only {
  grid-column: 2 / span 3;
}

.o-wrapper--read-full,
.page-cursos-online .o-wrapper--read,
.node-type-course .o-wrapper--read {
  display: block;
  width: 100%;
  max-width: 81.25rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .o-wrapper--read-full,
  .page-cursos-online .o-wrapper--read,
  .node-type-course .o-wrapper--read {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 1301px) {
  .o-wrapper--read-full,
  .page-cursos-online .o-wrapper--read,
  .node-type-course .o-wrapper--read {
    padding: 0;
  }
}

.node-type-course .o-main__read,
.page-cursos-online .o-main__read {
  grid-column: 2 / span 7;
}

.node-type-course .o-main__content,
.page-cursos-online .o-main__content {
  grid-column: 1 / span 6;
}

.node-course.o-main__content {
  display: flex;
  flex-wrap: wrap;
}

.node-course.o-main__content .field-name-field-image {
  width: 45%;
}

.node-course.o-main__content .field-name-field-image img {
  display: block;
  width: 100%;
}

.node-course.o-main__content .field-name-body {
  width: 50%;
  margin-left: auto;
}

.node-course.o-main__content .field-name-field-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.view-courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.view-courses .item-list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

@media screen and (min-width: 993px) {
  .view-courses > div {
    width: 32%;
  }
}

.node-course.node-teaser a {
  display: block;
}

.node-course.node-teaser a:hover {
  text-decoration: none;
}

.node-course.node-teaser a.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.node-course.node-teaser img {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}

.node-course.node-teaser h2 {
  margin: 0 0 8px;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.3;
}

.node-course.node-teaser .field-name-field-image {
  width: 100%;
}

.o-cta-call-header {
  text-align: center;
}

.o-cta-call-header h4 {
  display: inline-block;
  line-height: 1;
  color: var(--cl-primary);
  font-size: var(--fz-hl);
  padding: 0 var(--sp-unit);
  background-color: var(--cl-clean);
}

.o-cta-call-header em {
  display: block;
  font-style: normal;
  font-weight: 300;
  color: var(--cl-grey-50);
  font-size: var(--fz-xm);
  margin-bottom: 0.75rem;
}

@media screen and (min-width: 993px) {
  .o-cta-call-header {
    margin-bottom: -53px;
    position: relative;
    z-index: 5;
  }
  .o-cta-call-header h4 {
    margin-bottom: 0.75rem;
  }
  .o-cta-call-header em {
    font-size: var(--fz-l);
    margin-bottom: 0;
  }
}

.o-cta-call-footer {
  display: table;
  margin: -1.6875rem auto 0;
  text-align: center;
  position: relative;
}

.o-cta-call-footer__item {
  display: inline-block;
  padding: 0 var(--sp-unit);
  margin-bottom: var(--sp-half);
  background: var(--cl-clean);
}

.o-cta-call-footer__txt {
  display: block;
  font-size: var(--fz-s);
  line-height: 1.3;
  max-width: 17.5rem;
}

.o-cta-call-user h5,
.o-cta-call h5 {
  color: var(--cl-tertiary);
  font-weight: 300;
  line-height: 1;
}

.o-cta-call-user li::before,
.o-cta-call li::before {
  counter-increment: term;
  content: "0" counter(term);
  font-weight: 700;
}

.o-cta-call-user ol,
.o-cta-call ol {
  counter-reset: term;
}

.o-cta-call {
  overflow-x: auto;
  border: 2px solid var(--cl-secondary-light);
}

.o-cta-call h5 {
  font-size: var(--fz-hl);
  margin-top: -1.125rem;
}

.o-cta-call ol {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  grid-column-gap: 1rem;
  padding: var(--sp-double) var(--sp-unit);
}

.o-cta-call li::before {
  color: var(--cl-secondary-light);
  font-size: var(--fz-ul);
  background-color: var(--cl-clean);
}

@media screen and (min-width: 993px) {
  .o-cta-call ol {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    padding: var(--sp-triple) var(--sp-double);
    margin-top: var(--sp-double);
  }
}

body.o-modal-basic--active {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.o-modal-basic {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 60;
}

.o-modal-basic__close {
  color: var(--cl-clean);
  font-size: var(--fz-xl);
  background-color: var(--cl-secondary);
  line-height: .7;
  padding: 0.75rem 0.875rem 0.5rem;
  position: absolute;
  top: 2rem;
  right: 0;
}

.o-modal-basic__close:hover, .o-modal-basic__close:active, .o-modal-basic__close:focus {
  text-decoration: none;
}

.o-modal-basic__title {
  font: 300 2.25rem/1.2 "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  color: var(--cl-primary);
  margin-bottom: var(--sp-unit);
}

.o-modal-basic__wrapper {
  width: 95%;
  max-width: 60rem;
  min-height: 16.25rem;
  padding: var(--sp-unit) var(--sp-half);
  border-top: 4px solid var(--cl-secondary);
  margin: var(--sp-half) auto;
  background-color: var(--cl-secondary-xlight);
  position: relative;
}

.o-modal-basic.active {
  display: block !important;
}

@media screen and (min-width: 993px) {
  .o-modal-basic__wrapper {
    padding: var(--sp-double);
    margin: var(--sp-triple) auto;
  }
}

#login-page-from #login-links,
#login-page-from #register-form {
  display: none;
}

.o-login__logo {
  max-width: 14.6875rem;
  margin-bottom: var(--sp-and-half);
}

.o-login__title {
  text-transform: uppercase;
  color: var(--cl-primary-dark);
  font-size: var(--fz-hl);
  line-height: 1.2;
}

.o-login__header {
  text-align: center;
  margin-bottom: var(--sp-triple);
}

.o-login__form .item-list {
  float: right;
}

.o-login__form .form-actions {
  float: left;
}

.o-login__form .item-list li:first-child {
  display: none;
}

.o-login__form .item-list li:last-child {
  margin-top: 1.25rem;
}

.o-login__footer {
  text-align: center;
}

.o-login__footer hr {
  border-width: 2px;
  border-color: var(--cl-secondary-xlight);
}

.page-user-register .o-login__footer {
  display: none;
}

.o-login--w .modaal-container {
  max-width: 37.5rem;
}

.c-back-top {
  grid-column: 3;
  justify-self: end;
  display: none;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--fz-xs);
  padding: 0 var(--sp-quarter);
  border: 1px dotted transparent;
}

.c-back-top:hover, .c-back-top:active, .c-back-top:focus {
  text-decoration: none;
  border-color: #f0efec;
  border-color: var(--cl-secondary-light);
}

.c-back-top__icon {
  fill: var(--cl-clean);
  margin-right: var(--sp-half);
  width: 0.75rem;
  transform: rotate(-90deg);
}

@media screen and (min-width: 993px) {
  .c-back-top {
    display: inline-flex;
  }
}

.c-about {
  margin-bottom: var(--sp-and-half);
}

.c-about__title {
  font-weight: 400;
  font-size: var(--fz-m);
}

.c-company {
  opacity: .8;
}

.c-company__address {
  font-style: normal;
}

.c-company__title {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  color: var(--cl-clean);
}

.c-company__item {
  display: inline-block;
  margin: 0;
  font-size: var(--fz-s);
}

.c-company__institution {
  margin-right: var(--sp-unit);
}

.dev {
  padding: var(--sp-half) var(--sp-half) 0;
  background-color: var(--cl-clean);
  margin-left: auto;
}

.dev svg {
  fill: var(--cl-grey-60);
}

.region-footer {
  margin-bottom: var(--sp-double);
}

.region-footer .block__title {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  color: var(--cl-primary);
  margin-bottom: var(--sp-quarter);
}

.region-footer .block-menu-menu-footer-about {
  margin-bottom: var(--sp-unit);
}

@media screen and (min-width: 768px) {
  .region-footer {
    display: flex;
  }
}

@media screen and (min-width: 993px) {
  .region-footer .menu {
    display: flex;
    align-items: center;
  }
  .region-footer .menu__item:not(:first-child) {
    margin-left: var(--sp-unit);
  }
  .region-footer .block-menu-menu-footer-about {
    margin-bottom: 0;
  }
}

.region-footer-top {
  max-width: calc(3 * em(260px));
  grid-column: 2;
}

.o-footer {
  color: var(--cl-clean);
  background-color: var(--cl-grey-60);
}

.o-footer a {
  color: var(--cl-clean);
}

.o-footer__top {
  padding: var(--sp-and-half) 0;
  background-color: var(--cl-secondary);
}

.o-footer__main {
  padding: var(--sp-double) 0 var(--sp-triple);
}

.o-footer__bottom .o-wrapper {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 993px) {
  .o-footer__top .o-wrapper {
    display: grid;
    grid-template-columns: minmax(4rem, 16.25rem) auto minmax(4rem, 16.25rem);
    grid-auto-flow: column;
    align-items: center;
  }
  .o-footer__top {
    padding: var(--sp-and-half) 0;
  }
}

.c-product-type {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.c-product-type__label {
  overflow: hidden;
  text-indent: -100vw;
  display: block;
  color: var(--cl-primary);
  width: var(--sp-double);
  background-color: var(--cl-primary);
  margin-bottom: 0;
}

.c-product-type__item {
  margin: 0 0 -4px;
}

.field-name-field-arti-summary p:first-child {
  margin-top: 0;
}

.field-name-field-arti-summary p:last-child {
  margin-bottom: 0;
}

.field-name-field-tags .field-item:not(:last-child)::after {
  content: ',';
  margin-right: var(--sp-half);
}

.field-name-field-tags .field-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.field-name-field-arti-journal .field-label {
  margin: 0 0 var(--sp-half);
}

.field-name-field-arti-journal .field-label:nth-child(1) {
  grid-area: label01;
}

.field-name-field-arti-journal .field-label:nth-child(3) {
  grid-area: label02;
}

.field-name-field-arti-journal .field-label:nth-child(5) {
  grid-area: label03;
}

.field-name-field-arti-journal .field-item {
  color: var(--cl-primary);
  font-size: var(--fz-l);
  font-weight: 700;
}

.field-name-field-arti-journal .field-item:nth-child(2) {
  grid-area: item01;
}

.field-name-field-arti-journal .field-item:nth-child(4) {
  grid-area: item02;
}

.field-name-field-arti-journal .field-item:nth-child(6) {
  grid-area: item03;
}

.field-name-field-arti-journal .field-type-list-text {
  display: grid;
  grid-template-columns: repeat(2, minmax(4rem, 40%)) minmax(4rem, 20%);
  grid-column-gap: 4%;
  grid-template-areas: "label01 label02 label03" "item01  item02  item03";
}

.field-name-field-arti-journal .field-type-list-text::before, .field-name-field-arti-journal .field-type-list-text::after {
  display: none;
}

.field-name-field-arti-authors .profile .field {
  margin-bottom: 0 !important;
}

.field-name-field-arti-authors .profile {
  display: block;
  padding: var(--sp-half) 0;
  border-bottom: 2px solid var(--cl-secondary-light);
}

.field-name-field-arti-authors .profile:hover {
  border-color: var(--cl-secondary);
}

.field-name-field-arti-authors .field-label {
  margin-bottom: 0;
}

.field-name-field-arti-status {
  color: var(--cl-primary);
  font-size: var(--fz-xxm);
  font-weight: 700;
  padding-left: var(--sp-double);
  background: url("../images/ok-icon-green.svg") 0 center no-repeat;
}

.field-name-field-arti-file .file-icon {
  display: none;
}

.field-name-field-arti-file a {
  width: 13.75rem;
  overflow: hidden;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.field-name-field-arti-file a::before {
  content: 'Ler o Trabalho (PDF)';
  background: url("../images/pdf-icon-brown.svg") 0 center no-repeat;
  padding-left: var(--sp-double);
  margin-right: 100vw;
}

.field-name-field-data-de-envio,
.field-name-field-arti-publication-date,
.field-name-field-arti-certification {
  display: inline-block;
}

.field-name-field-data-de-envio,
.field-name-field-arti-publication-date {
  margin-right: var(--sp-and-half);
}

.referenciar strong {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
}

.referenciar p {
  margin: 0;
  padding: var(--sp-unit);
}

.referenciar p:first-child {
  padding-top: 0;
}

.referenciar p:last-child {
  position: relative;
  word-break: break-word;
  background-color: var(--cl-grey-10);
}

.referenciar p:last-child::after {
  content: "";
  display: block;
  width: 50%;
  height: 100%;
  background-color: var(--cl-grey-10);
  position: absolute;
  bottom: 0;
  right: -50%;
}

@media screen and (min-width: 993px) {
  .referenciar p {
    padding: var(--sp-double);
  }
}

.o-product-content {
  margin-bottom: var(--sp-triple);
}

.o-product-content .field:not(:last-child) {
  margin-bottom: var(--sp-and-half);
}

.o-product-aside-one {
  margin-bottom: var(--sp-triple);
}

.o-product-aside-one .field:not(:last-child) {
  margin-bottom: var(--sp-and-half);
}

.o-product-aside-one .field.field-name-field-arti-status {
  margin-bottom: var(--sp-half);
}

@media screen and (max-width: 992px) {
  .o-product-aside-one {
    position: relative;
    padding-left: var(--sp-quad);
  }
}

@media screen and (max-width: 992px) {
  .o-product-aside-two {
    margin-bottom: var(--sp-double);
  }
}

.node-type-article .o-article {
  position: relative;
}

@media screen and (min-width: 993px) {
  .node-type-article .o-article {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 48px;
    grid-template-areas: "head    asd-one" "read    asd-one" "asd-two asd-two";
  }
  .node-type-article .o-main__header {
    grid-area: head;
  }
  .o-product-content {
    grid-area: read;
  }
  .o-product-aside-one {
    grid-area: asd-one;
  }
  .o-product-aside-two {
    grid-area: asd-two;
  }
}

.o-authors .field-name-realname,
.o-authors .field-name-field-user-name {
  display: none;
}

.o-authors .profile {
  margin: 0;
}

.o-authors .field:not(.field-name-field-user-summary) {
  margin-bottom: var(--sp-unit);
}

.o-authors .field-name-field-user-citation-name .field-items {
  font-weight: 700;
  color: var(--cl-primary);
  font-size: var(--fz-xxm);
}

.o-authors .field-name-field-user-lattes a {
  display: block;
}

.o-authors .field-name-field-user-summary strong {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
}

.o-authors .o-modal-basic__title {
  align-self: end;
  color: var(--cl-primary-dark);
}

.o-authors .o-modal-basic__title::before {
  content: '';
  display: block;
  width: 4rem;
  height: 4rem;
  background: transparent url("../images/user-icon.svg") center no-repeat;
  background-color: var(--cl-primary);
  background-size: 50%;
}

.o-authors .o-modal-basic__content {
  border-top: 1px dashed var(--cl-primary);
}

@media screen and (min-width: 993px) {
  .o-authors .o-modal-basic__title {
    max-width: 200px;
    padding-top: 6.25rem;
  }
  .o-authors .o-modal-basic__title::before {
    width: 6.25rem;
    height: 6.25rem;
    position: absolute;
    top: 32px;
    left: -3.125rem;
  }
  .o-authors .o-modal-basic__content {
    display: flex;
    flex-flow: column;
    border-top: 0;
    height: 100%;
    padding-left: var(--sp-unit);
    border-left: 1px dashed var(--cl-primary);
  }
  .o-authors .o-modal-basic__wrapper {
    display: grid;
    grid-template-columns: 33% auto;
    align-items: center;
  }
}

.u-ttu {
  text-transform: uppercase;
}

.u-dn {
  display: none;
}

.u-db {
  display: block;
}

.u-dib {
  display: inline-block;
}

.u-fwb {
  font-weight: 700;
}

.u-fllf {
  float: left;
}

.u-flrg {
  float: right;
}

.u-radius-circle {
  border-radius: 50%;
}

.u-no-link {
  cursor: not-allowed;
  pointer-events: none;
}

.u-mobile-scroll-horizontal {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.u-clear-list {
  list-style: none;
  padding: 0;
}

.u-display-label {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
}

.u-horizontal {
  display: flex;
  align-items: center;
}

.u-list-horizontal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.u-transition-basic {
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.u-flexbox-centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-cl-white {
  color: var(--cl-clean);
}

.u-cl-black {
  color: var(--cl-dark);
}

.u-cl-text {
  color: var(--cl-text);
}

.u-cl-link-visited {
  color: var(--cl-link-visited);
}

.u-cl-grey-10 {
  color: var(--cl-grey-10);
}

.u-cl-grey-20 {
  color: var(--cl-grey-20);
}

.u-cl-grey-30 {
  color: var(--cl-grey-30);
}

.u-cl-grey-40 {
  color: var(--cl-grey-40);
}

.u-cl-grey-50 {
  color: var(--cl-grey-50);
}

.u-cl-grey-60 {
  color: var(--cl-grey-60);
}

.u-cl-grey-70 {
  color: var(--cl-grey-70);
}

.u-cl-grey-80 {
  color: var(--cl-grey-80);
}

.u-cl-grey-90 {
  color: var(--cl-grey-90);
}

.u-cl-sucess {
  color: var(--cl-sucess);
}

.u-cl-info {
  color: var(--cl-info);
}

.u-cl-warning {
  color: var(--cl-warning);
}

.u-cl-error {
  color: var(--cl-error);
}

.u-cl-primary {
  color: var(--cl-primary);
}

.u-cl-secondary {
  color: var(--cl-secondary);
}

.u-cl-tertiary {
  color: var(--cl-tertiary);
}

.u-cl-primary-dark {
  color: var(--cl-primary-dark);
}

.u-bgc-white {
  background-color: var(--cl-clean);
}

.u-bgc-black {
  background-color: var(--cl-dark);
}

.u-bgc-text {
  background-color: var(--cl-text);
}

.u-bgc-link-visited {
  background-color: var(--cl-link-visited);
}

.u-bgc-grey-10 {
  background-color: var(--cl-grey-10);
}

.u-bgc-grey-20 {
  background-color: var(--cl-grey-20);
}

.u-bgc-grey-30 {
  background-color: var(--cl-grey-30);
}

.u-bgc-grey-40 {
  background-color: var(--cl-grey-40);
}

.u-bgc-grey-50 {
  background-color: var(--cl-grey-50);
}

.u-bgc-grey-60 {
  background-color: var(--cl-grey-60);
}

.u-bgc-grey-70 {
  background-color: var(--cl-grey-70);
}

.u-bgc-grey-80 {
  background-color: var(--cl-grey-80);
}

.u-bgc-grey-90 {
  background-color: var(--cl-grey-90);
}

.u-bgc-sucess {
  background-color: var(--cl-sucess);
}

.u-bgc-info {
  background-color: var(--cl-info);
}

.u-bgc-warning {
  background-color: var(--cl-warning);
}

.u-bgc-error {
  background-color: var(--cl-error);
}

.u-bgc-primary {
  background-color: var(--cl-primary);
}

.u-bgc-secondary {
  background-color: var(--cl-secondary);
}

.u-bgc-tertiary {
  background-color: var(--cl-tertiary);
}

.u-bgc-primary-dark {
  background-color: var(--cl-primary-dark);
}

/*!
    Modaal - accessible modals - v0.2.9
    by Humaan, for all humans.
    http://humaan.com
 */
.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.modaal-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
}

.modaal-wrapper .modaal-close {
  border: none;
  background: transparent;
  padding: 0;
  -webkit-appearance: none;
}

.modaal-wrapper.modaal-start_none {
  display: none;
  opacity: 1;
}

.modaal-wrapper.modaal-start_fade {
  opacity: 0;
}

.modaal-wrapper *[tabindex="0"] {
  outline: none !important;
}

.modaal-wrapper.modaal-fullscreen {
  overflow: hidden;
}

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}

.modaal-fullscreen .modaal-outer-wrapper {
  display: block;
}

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px;
}

.modaal-fullscreen .modaal-inner-wrapper {
  padding: 0;
  display: block;
  vertical-align: top;
}

@media only screen and (max-width: 767px) {
  .modaal-inner-wrapper {
    padding: 40px 8px;
  }
}

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto;
}

.modaal-container.is_loading {
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.modaal-fullscreen .modaal-container {
  max-width: none;
  height: 100%;
  overflow: auto;
}

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.modaal-close:focus, .modaal-close:hover {
  outline: none;
  background: #fff;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #b93d0c;
}

.modaal-close span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-close:before, .modaal-close:after {
  display: block;
  content: " ";
  position: absolute;
  top: 14px;
  left: 23px;
  width: 4px;
  height: 22px;
  border-radius: 4px;
  background: #fff;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

.modaal-close:before {
  transform: rotate(-45deg);
}

.modaal-close:after {
  transform: rotate(45deg);
}

.modaal-fullscreen .modaal-close {
  background: #afb7bc;
  right: 10px;
  top: 10px;
}

.modaal-content-container {
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .modaal-content-container {
    padding: 30px 10px;
  }
}

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0;
}

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent;
}

.modaal-confirm-btn.modaal-ok {
  padding: 10px 15px;
  color: #fff;
  background: #555;
  border-radius: 3px;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

.modaal-confirm-btn.modaal-ok:hover {
  background: #2f2f2f;
}

.modaal-confirm-btn.modaal-cancel {
  text-decoration: underline;
}

.modaal-confirm-btn.modaal-cancel:hover {
  text-decoration: none;
  color: #2f2f2f;
}

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important;
}

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent;
}

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
}

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation-name: instaReveal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes instaReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modaal-image .modaal-container {
  width: auto;
  max-width: 1500px;
}

.modaal-gallery-wrap {
  position: relative;
  color: #fff;
}

.modaal-gallery-item {
  display: none;
}

.modaal-gallery-item img {
  display: block;
}

.modaal-gallery-item.is_active {
  display: block;
}

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.modaal-gallery-label:focus {
  outline: none;
}

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.modaal-gallery-control.is_hidden {
  opacity: 0;
  cursor: default;
}

.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  outline: none;
  background: #fff;
}

.modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
  background: #afb7bc;
}

.modaal-gallery-control span {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.modaal-gallery-control:before, .modaal-gallery-control:after {
  display: block;
  content: " ";
  position: absolute;
  top: 16px;
  left: 25px;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  background: #fff;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

.modaal-gallery-control:before {
  margin: -5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-control:after {
  margin: 5px 0 0;
  transform: rotate(45deg);
}

.modaal-gallery-next {
  left: 100%;
  margin-left: 40px;
}

.modaal-gallery-prev {
  right: 100%;
  margin-right: 40px;
}

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px;
}

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg);
}

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg);
}

.modaal-video-wrap {
  margin: auto 50px;
  position: relative;
}

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
}

.modaal-video-container iframe,
.modaal-video-container object,
.modaal-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modaal-iframe .modaal-container {
  width: auto;
  max-width: none;
}

.modaal-iframe-elem {
  width: 100%;
  display: block;
}

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1140px) {
  .modaal-gallery-item img {
    width: 100%;
  }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7);
  }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    background: #fff;
  }
  .modaal-gallery-next {
    left: auto;
    right: 20px;
  }
  .modaal-gallery-prev {
    left: 20px;
    right: auto;
  }
}

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
}

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important;
  }
}

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .modaal-instagram iframe {
    width: 600px !important;
  }
}

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important;
  }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px;
  }
}

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important;
  }
}

@media only screen and (max-height: 1024px) {
  .modaal-gallery-item img {
    width: auto !important;
    max-height: 85vh;
  }
}

@media only screen and (max-height: 1024px) {
  .modaal-gallery-label {
    display: none;
  }
}

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -ms-transform: scale(0.25);
  transform: scale(0.25);
}

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff;
}

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s;
}

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s;
}

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s;
}

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s;
}

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
}

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s;
}

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s;
}

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s;
}

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}
