// Resizable textarea
//
// A textarea that can be resized with a "grippie" widget.
//
// Markup: resizable-textarea.twig
//
// Style guide: forms.resizable-textarea

.resizable-textarea,
%resizable-textarea {
  width: 100%;
  vertical-align: bottom;

  &__grippie {
//    background: image-url('forms', 'resizable-textarea/grippie.png') no-repeat center 2px color(resizable-grippie);
    background: url('#{$imgPath}/grippie.png') no-repeat center 2px color(resizable-grippie);
    border: 1px solid color(border);
    border-top-width: 0;
    cursor: s-resize;
    height: 9px;
    overflow: hidden;
  }
}

//
// Drupal selectors.
//

.resizable-textarea {
  textarea {
    @extend %resizable-textarea;
  }

  .grippie {
    @extend %resizable-textarea__grippie;
  }
}
