// Button
//
// In addition to the default styling of `<button>` and
// `<input type="submit|image|reset|button">` elements, the `.button` class and
// its variants can apply buttons styles to various elements (like an `<a>`
// link).
//
// :hover  - Hover styling.
// :active - Depressed button styling.
//
// Markup: button.twig
//
// Style guide: forms.button

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
//.c-btn,
//.button,
//%button,
//button,
//// 1
//html [type='button'],
//[type='reset'],
//[type='submit'] {
//  -moz-appearance: button;
//  -webkit-appearance: button; // 2
//}

.c-btn,
.button,
%button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  @extend %button--disabled;
  // Some styles don't apply to <a> links since they are inline elements by default.
//  display: inline-block;
  display: inline-flex;
  align-items: center;
  // Address Firefox 4+ setting `line-height` on `input` using `!important` in
  // the UA stylesheet. And prevent its "line-height: normal" rule below from
  // breaking the "appearance: button" styling on the input elements.
  font: 700 1rem/#{$line-height-reset} $roboto;
  letter-spacing: em(0.3px);
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: $n;
  color: var(--cl-clean);
  padding: var(--sp-half) var(--sp-unit);
  border: 2px solid var(--cl-secondary);
  background-color: var(--cl-secondary);
  height: em(50px);
//  max-height: em(50px);
  margin: 0;
  // Improve usability and consistency of cursor style between image-type `input` and others.
  cursor: pointer;
  @include transition(all $duration-quickly ease-in-out);

  // Remove the inner border and padding in Firefox.
  &::-moz-focus-inner {
    border-style: $n;
    padding: 0;
  }

  // Restore the focus styles unset by the previous rule.
  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  @include hover() {
    // Override any link underlines and color changes.
    text-decoration: $n;
    color: var(--cl-clean);
  }
}


// Add button variations here.
//.button,
//%button {
//    &--variant-name {}
//}

.btn-new {
  position: relative;

  &::after {
    content: "novo";
    color: var(--cl-clean);
    font-size: var(--fz-xxs);
    background-color: var(--cl-tertiary);
    letter-spacing: 1px;
    padding: 3px 6px;
    border-radius: 2px;
    position: absolute;
    top: -8px;
    right: 8px;
  }
}

.c-btn {
  text-transform: $in;
  
  &__icon {
    fill: $cc;
    margin-right: var(--sp-half);
  }
  
//  .c-btn--small {
//    padding: $global-spacing-unit-tiny $global-spacing-unit-small;
//  }
  
  &--large { padding: var(--sp-unit) var(--sp-and-half); }
  
  &--primary {
    color: var(--cl-secondary);
    border-color: var(--cl-secondary-light);
    background-color: var(--cl-clean);

//    @include hover {
//        color: var(--cl-secondary);
//        border-color: var(--cl-secondary);
//    }
    
    &:hover,
    &:focus {
      color: var(--cl-secondary);
      border-color: var(--cl-secondary);
    }
    
    &:active {
      color: var(--cl-clean);
      border-color: var(--cl-secondary);
      background-color: var(--cl-secondary);
    }

//    .t-red & {
//      background-color: $color-theme-red;
//    }
  }
  
//  &--secondary {
//    background-color: $color-btn-secondary-background;
//               color: $color-btn-secondary;
//    border-radius: $global-radius;
//    transition: $global-transition;
//
//    @include hocus() {
//      background-color: $color-btn-secondary-background-hover;
//    }
//
//  }
  
  &--cta {
    color: var(--cl-clean);
    border-color: var(--cl-tertiary);
    background-color: var(--cl-tertiary);

    @include hover {
      color: var(--cl-clean);
    }
    
    .c-cta-enviar-publicacao & { @extend .t-btn-rwd; }
  }
  
  // cart
  // btns used in cart and cart header
  &--remove {
    @include size(em(28px));
    background-color: var(--cl-clean);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    text-indent: -100px;
    
    @include hover {
      background-color: var(--cl-secondary);
    }
  }
  
  &--remove {
    background-image: url('#{$imgPath}/remove-icon-brown.svg');
    
    @include hover {
      background-image: url('#{$imgPath}/remove-icon-white.svg');
    }
  }
}

.t-btn-rwd {
  @include media('screen', '<=medium') {
    width: em(50px);
    padding: 0;
    justify-content: center;

    .c-btn__icon { margin-right: 0; }
    .c-btn__title { display: $n; }
  }
}

.t-nav-btn,
.t-btn-login { @extend .t-btn-rwd; }

.t-btn-cart {
  @include flexbox-centering;
  @include size(em(50px));
  padding: 0;
  border-color: var(--cl-secondary-light);
  background-color: var(--cl-secondary-light);
  position: relative;
  
  &__icon { fill: var(--cl-secondary); }

  &__qtd {
    font-weight: 700;
    color: var(--cl-clean);
    font-size: var(--fz-xs);
    background-color: var(--cl-secondary);
    @include size(em(20px));
    @include flexbox-centering;
    border-radius: $border-radius-circle;
    position: absolute;
    left: -10px;
  }

  &:hover { border-color: var(--cl-secondary); }

  &[aria-expanded="true"] {
    border-color: var(--cl-secondary);
    background-color: var(--cl-secondary);

    .t-btn-cart__icon { fill: var(--cl-clean); }

    .t-btn-cart__qtd {
      color: var(--cl-secondary);
      background-color: var(--cl-secondary-light);
    }
  }
}

.t-btn-user {
  @extend .t-btn-rwd;
  @include horizontal;
  justify-content: center;
  width: em(210px);
  
  .c-btn__icon { width: em(28px); }

  .c-btn__title {
    margin-right: var(--sp-half);
    @include truncate-text(em(124px));
  }
  
  .field-items { @include horizontal; }
  
  .field-items::before {
    @include triangle(left, $cc, 4px);
    margin-right: var(--sp-quarter);
  }
  
  &[aria-expanded="true"] {
    color: var(--cl-clean);
    background-color: var(--cl-secondary);

    .c-btn__icon { fill: var(--cl-secondary-light); }
    .field-items::before { transform: rotate(90deg); }
  }
}

.login-forgot a {
  @extend .c-btn;
  @extend .c-btn--primary;
  border-color: $t;
  
  @include hover {
    border-color: $cc;
  }
}

.page-cart-order-complete a.btn { @extend .c-btn; }
#edit-cancel { @extend .c-btn--primary; }


// The disabled variation should always go last, so that it overrides any
// other variations.
%button--disabled[disabled] {
  // Re-set default cursor for disabled elements.
  cursor: default;
  color: color(button-disabled);

  @include hover {
    // Override any link underlines and color changes.
    color: color(button-disabled);
  }
}



// btns cards
.views-field-edit-delete .form-submit {
  @extend .c-btn;
  @extend .c-btn--remove;
  border: 0;
}

.line-item-summary-view-cart a, 
.line-item-summary-checkout a {
  @extend .c-btn;
  @extend .c-btn--primary;
  @extend .c-btn--large;
  display: block;
  border: 0;
}

.line-item-summary-view-cart a {
  @include hover {
    color: var(--cl-clean);
    background-color: var(--cl-secondary);
  }
}

.line-item-summary-checkout a {
  color: var(--cl-tertiary);
  
  @include hover {
    color: var(--cl-clean);
    background-color: var(--cl-tertiary);
  }
}
// btns cards


// .page-user .form-actions, 
// .node-article-form .form-actions {
.form-actions {
  display: flex;
  justify-content: space-around;
}

.checkout-buttons #edit-continue, 
.form-actions #edit-submit:not([value="Buscar"]),
.form-actions #edit-checkout {
  @extend .c-btn--cta;
  justify-content: center;
  width: 200px;
}
.checkout-buttons #edit-continue { width: auto; }

// btns objects-product-form
.node-article-form #edit-preview, 
#edit-field-arti-authors .field-add-more-submit { @extend .c-btn--primary; }
// btns objects-product-form

// btns objects-page-checkout
.checkout-buttons .checkout-cancel { @extend .c-btn; }

.view-commerce-cart-form #edit-submit, 
.checkout-buttons .checkout-cancel { @extend .c-btn--primary; }
// btns objects-page-checkout
