// Dependencies.
@import 'components/clearfix/clearfix';

// Form item
//
// Wrapper for a form element (or group of form elements) and its label.
//
// .form-item--inline - Inline form items.
// .form-item--tight  - Packs groups of form items closer together.
// .is-error          - Highlight the form elements that caused a form
//                      submission error.
//
// Markup: form-item.twig
//
// Style guide: forms.form-item

// Form item (radio)
//
// Checkboxes and radios require slightly different markup; their label is after
// their widget instead of before. Uses the `.form-item--radio` class variant of
// the normal form item and is placed on each of the nested form items.
//
// .is-error - Highlight the form elements that caused a form submission error.
//
// Markup: form-item--radio.twig
//
// Style guide: forms.form-item--radio

.form-item,
%form-item {
//  @include margin(1 0);
  margin-bottom: var(--sp-unit);

  // The part of the label that indicates a required field.
  &__required { color: color(form-error); }

  // The descriptive help text (separate from the label).
  &__description {
//    @include font-size(s);
    font: 400 0.875rem/#{$line-height-text} $roboto;
    color: var(--cl-grey-50);
    margin-top: var(--sp-half);
  }

  &--inline {
    div,
    label { display: inline; }

    // There's always an exception.
    &__exception { display: block; }
  }

  &--tight { margin: 0; }

  &--radio {
    @extend %form-item--tight;
  }

  &--radio &__label,
  &--radio__label {
    display: inline;
    font-size: 1rem;
    font-weight: normal;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &--radio &__description,
  &--radio__description { margin-left: 1.4em; }

  &.is-error &__widget,
  &--is-error__widget { border: 1px solid color(form-error); }

  &.is-error,
  &--is-error {
    input,
    textarea,
    select {
      @extend %form-item--is-error__widget;
    }
  }
}

//
// Drupal selectors.
//

.form-item {
  input.error,
  textarea.error,
  select.error {
    @extend %form-item--is-error__widget;
  }

  .description {
    @extend %form-item__description;
  }
}

.form-required {
  @extend %form-item__required;
}

// Password confirmation.
.password-parent,
.confirm-parent {
  @extend %form-item--tight;
}

table {
  .form-item {
    @extend %form-item--tight;
  }
}

.form-type-radio,
.form-type-checkbox {
  @extend %form-item--radio;

  .description {
    @extend %form-item--radio__description;
  }
}

label.option {
  @extend %form-item--radio__label;
}

.container-inline {
  .form-item {
    @extend %form-item--inline;
  }

  // Fieldset contents always need to be rendered as block.
  .fieldset-wrapper {
    @extend %form-item--inline__exception;
  }
}



.fieldset-description {
  @extend %form-item__description;
  margin-top: 0;
  margin-bottom: var(--sp-unit);
}

.views-exposed-form {
  margin-bottom: em(32px);
  
  #views-exposed-form-validacao-certificado-page & { margin-bottom: 0; }
}

.views-exposed-form .views-exposed-widget .form-submit, 
.views-exposed-widgets { margin: 0; }

.views-exposed-form .views-exposed-widget {
  float: $in;
  padding: $in;
  margin-bottom: em(12px);
  
  &:last-child { margin-bottom: 0; }
}

.chosen-container { width: 100% $i; }

.form-file {
  line-height: em(36px);
  width: 100%;
  border: 2px solid var(--cl-secondary-light);
}



