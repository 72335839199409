///*------------------------------------*\
//    #LAYOUTS - AUTHORS
//\*------------------------------------*/

$w-img-user: em(100px);
$w-img-user--half: em(50px);

.o-authors {
  .field-name-realname, 
  .field-name-field-user-name { display: $n; }
  .profile { margin: 0; }
  
  .field:not(.field-name-field-user-summary) { margin-bottom: var(--sp-unit); }
  
  .field-name-field-user-citation-name .field-items {
    font-weight: 700;
    color: var(--cl-primary);
    font-size: var(--fz-xxm);
  }
  
  .field-name-field-user-lattes a { display: block; }
  .field-name-field-user-summary strong { @include label; }
  
  .o-modal-basic__title {
    align-self: end;
    color: var(--cl-primary-dark);
    
    &::before {
      content: '';
      display: block;
      @include size(em(64px));
      background: $t url('#{$imgPath}/user-icon.svg') center no-repeat;
      background-color: var(--cl-primary);
      background-size: 50%;
    }
  }
  
  .o-modal-basic__content { border-top: 1px dashed var(--cl-primary); }
  
  @include media('screen', '>medium') {
    .o-modal-basic__title {
      max-width: 200px;
      padding-top: $w-img-user;
      
      &::before {
        @include size($w-img-user);
        position: absolute;
        top: 32px;
        left: -($w-img-user--half);
      }
    }
    
    .o-modal-basic__content {
      display: flex;
      flex-flow: column;
      border-top: 0;
      height: 100%;
      padding-left: var(--sp-unit);
      border-left: 1px dashed var(--cl-primary);
    }
    
    .o-modal-basic__wrapper {
      display: grid;
      grid-template-columns: 33% auto;
      align-items: center;
    }
  }
}
