///*------------------------------------*\
//    #LAYOUTS - MAIN
//\*------------------------------------*/

$minD: em(64px);


p:first-child { margin-top: 0; }
p:last-child { margin-bottom: 0; }
//.field-name-body {
//}

.page-search #search-form { display: $n; }

table {
  @extend .table;
  @extend .table-striped;
  margin: 0 0 var(--sp-and-half);
}

.view-meus-pedidos { @extend .table-responsive; }

// tabs
.o-tabs-system {
  padding-top: em(16px) $i;
  padding-bottom: em(32px) $i;
}
.o-tabs-system .tabs { margin: 0; }
.t-user.o-tabs-system { display: $n; }
.logged-in.node-type-article:not(.page-node-edit) .t-user.o-tabs-system, 
.logged-in.page-node-edit .t-user.o-tabs-system {
  padding-top: 0 $i;
  display: block;
}

.o-action-links { margin-bottom: var(--sp-triple); }

.view-header h2 {
  margin-top: 0;
  font-family: $roboto;
  font-weight: 400;
  color: var(--cl-primary);
  font-size: var(--fz-xxm);
}

.view-empty h1 {
  color: var(--cl-clean);
  font-size: var(--fz-xxm);
  padding: var(--sp-double);
  background-color: var(--cl-tertiary);
  line-height: 1.2;
  margin: 0;
}

.view-articles .view-header, 
.view-articles .view-footer { display: $n; }

#user-login .form-type-password { margin-bottom: em(32px); }
#user-login .login-forgot { float: right; }
#user-login .form-actions { display: inline-flex; }

#edit-preview-changes { display: $n; }


.o-main {
  position: relative;
  margin-bottom: var(--sp-triple);

  &__header {
    margin-bottom: var(--sp-double);

    .node-type-article & { padding-top: var(--sp-unit); }
  }
  
  @include media('screen', '>medium') {
    margin-bottom: var(--sp-quad);
  }
}

.t-two-cols {
  @include media('screen', '>medium') {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 48px;
  }
}

.o-wrapper--read {
  display: grid;
  grid-template-columns: minmax(em(8px), 1fr) repeat(4, minmax($minD, em(260px))) minmax(em(8px), 1fr);
  grid-template-areas: 
    "read read      read      read      read      ."
    ".    aside-one aside-one aside-one aside-one ."
    ".    aside-two aside-two aside-two aside-two .";
  
  .page-journal &,
  .node-type-article:not(.page-node-edit) & {
    grid-template-areas: 
    "read read      read      read      read      read"
    ".    aside-two aside-two aside-two aside-two .";
  }
  
  .page-checkout & {
    grid-template-areas: 
      "read read read read read read"
  }
  
  @include media('screen', '>medium') {
    grid-template-columns: minmax(em(16px), 1fr) repeat(5, minmax($minD, em(260px))) minmax(em(16px), 1fr);
    grid-template-areas: 
      "read read      read      read      aside-one aside-one ."
      ".    aside-two aside-two aside-two aside-two aside-two .";
    
    .node-type-article:not(.page-node-edit) & {
      grid-template-areas: 
        "read read      read      read      read      read      read"
        ".    aside-two aside-two aside-two aside-two aside-two .";
    }
    
    .o-main--user & {
      grid-template-areas: 
        "read read read read aside-one aside-one .";
    }
    
    .page-checkout & {
      grid-template-areas: 
        "read read read read read read read"
    }
  }
}

// article tag
.o-main__read {
  grid-area: read;
  margin-bottom: var(--sp-triple);
  
  display: grid;
  grid-template-columns: minmax(em(8px), 1fr) repeat(4, minmax($minD, em(260px)));
  grid-template-rows: auto 100%; // quando tem poucos itens
  grid-template-areas: 
    "header header header header header"
    ".      text   text   text   text";
  
  .node-type-article:not(.page-node-edit) & {
    grid-template-columns: minmax(em(8px), 1fr) repeat(4, minmax($minD, em(260px))) minmax(em(8px), 1fr);
    grid-template-rows: em(12px) minmax(em(16px), auto);
    grid-auto-rows: auto;
    grid-template-areas: 
      "type    type   type   type   type   ."
      "header  header header header header ."
      ".       text   text   text   text   ."
      ".       ref    ref    ref    ref    ref"
      ".       share  infos  infos  infos  .";
  }
  
  .page-checkout & {
    grid-template-columns: minmax(em(8px), 1fr) repeat(4, minmax($minD, em(260px))) minmax(em(8px), 1fr);
    grid-template-areas: 
      "header header header header header ."
      ".      text   text   text   text   ."
  }
  
  .page-meus-pedidos &, 
  .page-emitir-certificado-online &, 
  // .page-meus-artigos &, 
  .page-cart &, 
  .page-node-add-article & { margin-bottom: 0; }
  
  // Desktop
  @include media('screen', '>medium') {
    grid-template-columns: minmax(em(16px), 1fr) repeat(3, minmax($minD, em(260px)));
    grid-template-areas: 
    "header header header header"
    ".      .      text   text";
    
    margin-bottom: var(--sp-quad);
    
    .node-type-article:not(.page-node-edit) & {
      grid-template-columns: minmax(em(16px), 1fr) repeat(5, minmax($minD, em(260px))) minmax(em(16px), 1fr);
      grid-template-rows: em(12px) em(59px) minmax(em(16px), auto);
//      grid-auto-rows: auto;
      grid-template-areas: 
      "type   type   type   type   share share ."
      "header header header header share share ."
      "header header header header infos infos ."
      ".      .      text   text   infos infos ."
      ".      ref    ref    ref    ref   ref   ref";
    }
    
    .o-main--user & {
      grid-template-rows: auto 1fr;
      margin-bottom: 0;
    }
    
    .page-checkout & {
      grid-template-columns: minmax(em(16px), 1fr) repeat(5, minmax($minD, em(260px))) minmax(em(16px), 1fr);
      grid-template-areas: 
        "header header header header .     .     ."
        ".      text   text   text   text  text  ."
    }
  }
}

// .o-main__header {
//   grid-area: header;
  
//     margin-bottom: var(--sp-double);
// }

// .o-main__content {
//   grid-area: text;
  
//   @include media('screen', '>medium') {
//     .page-node-add-article &, 
//     .page-meus-pedidos &, 
//     .page-emitir-certificado-online &, 
//     .page-meus-artigos &, 
//     .page-user &, 
//     .page-cart & {
//       grid-area: $in;
//       grid-column: 2 / span 3;
//     }

//     .page-user & { grid-row: 2; }
//   }
// }

.page-toboggan-denied .toboggan-unified, 
.view-commerce-cart-form, 
.cart-empty-page {
  grid-area: text;
  
  @include media('screen', '>medium') {
    grid-column: 2 / span 3;
  }
}

.page-cart-order-complete .o-main__read p {
  grid-column: 2 / span 4;
  
  @include media('screen', '>medium') {
    grid-column: 2 / span 3;
  }
}

.page-cart-order-complete .o-main__read p { margin-top: 0; }

// equal .o-main__content in cart page and checkout
// #commerce-checkout-form-checkout, 
// #commerce-checkout-form-complete { grid-area: text; }

.o-sidebar-first {
  overflow: hidden;
  margin-bottom: var(--sp-triple);
  
  @include media('screen', '<=medium') {
    margin-top: var(--sp-triple);
  }
}

.o-sidebar-second {
  width: 100%;
  margin-top: var(--sp-triple);

  @include media('screen', '>medium') {
    margin-top: var(--sp-quad);
  }
}


#legal-login, 
.read-content-only {
  grid-column: 2 / span 3;
}



// pagina de cursos online
.o-wrapper--read-full, 
.page-cursos-online .o-wrapper--read,
.node-type-course .o-wrapper--read {
  display: block;
  @include o-wrapper;
}

.node-type-course .o-main__read, 
.page-cursos-online .o-main__read { grid-column: 2 / span 7; }

.node-type-course .o-main__content, 
.page-cursos-online .o-main__content { grid-column: 1 / span 6; }

// .page-cursos-online .o-main__header { max-width: 50%; }

.node-course.o-main__content {
  display: flex;
  flex-wrap: wrap;

  .field-name-field-image {
    width: 45%;

    img {
      display: block;
      width: 100%;
    }
  }
  
  .field-name-body {
    width: 50%;
    margin-left: auto;
  }
  
  .field-name-field-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
  }
}

.view-courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .item-list {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  @include media('screen', '>medium') {
    > div { width: 32%; }
  }
}

.node-course.node-teaser {
  a { display: block; }
  a:hover { text-decoration: none; }
  a.button { 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }

  h2 {
    margin: 0 0 8px;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
  }

  .field-name-field-image { width: 100%; }
}
// pagina de cursos online
