///*------------------------------------*\
//    #LAYOUTS - MODAL BASIC
//\*------------------------------------*/

body.o-modal-basic--active {
  overflow: hidden;
  @include size(100%);
}

.o-modal-basic {
  overflow-x: hidden;
  @include size(100%);
  background-color: rgba(0, 0, 0, .8);
  @include centerer;
  position: fixed;
  z-index: 60;
  
  &__close {
    color: var(--cl-clean);
    font-size: var(--fz-xl);
    background-color: var(--cl-secondary);
    line-height: .7;
    padding: em(12px) em(14px) em(8px);
    position: absolute;
    top: em(32px);
    right: 0;
    
    @include hover {
      text-decoration: $n;
    }
  }
  
  &__title {
    font: 300 em(36px)/1.2 $roboto;
    color: var(--cl-primary);
    margin-bottom: var(--sp-unit);
  }
  
  &__wrapper {
    width: 95%;
    max-width: em(960px);
    min-height: em(260px);
    padding: var(--sp-unit) var(--sp-half);
    border-top: 4px solid var(--cl-secondary);
    margin: var(--sp-half) auto;
    background-color: var(--cl-secondary-xlight);
    position: relative;
  }
  
  &.active { display: block $i; }
  
  @include media('screen', '>medium') {
    &__wrapper {
      padding: var(--sp-double);
      margin: var(--sp-triple) auto;
    }
  }
}
