///*------------------------------------*\
//    #LAYOUTS - NAVIGATIONS
//\*------------------------------------*/

$publicacoes: '.menu__link[href="/publicacoes"]';

@mixin active-trail {
  font-weight: 700;
  color: var(--cl-primary);
  border-color: var(--cl-primary);
}

.c-menu-institution {
  .block__title { @include visually-hidden; }
  
  .menu {
    @include horizontal;
  }
}

.c-menu-interaction {
  margin-left: auto; // align to right in flex
  
  .block__title { @include visually-hidden; }
  &--footer { margin-left: auto; }
  
  
  @include media('screen', '>medium') {
    .menu {
      @include horizontal;
    }
  }
}

.t-nav-btn .c-btn__icon { width: em(30px); }
.rdw-fixed-btns {
  display: $n;

  a.t-btn-login { margin-top: 0; }
  .c-btn--cta { grid-column: 2; }

  @include media('screen', '<=medium') {
    display: grid;
    grid-template-columns: 50px auto 50px;
    grid-column-gap: 8px;
    width: 100vw;
    padding: var(--sp-half);
    border-top: 1px solid var(--cl-secondary-light);
    background-color: var(--cl-clean);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 50;
  }
}


.region-navigation {
  @include o-wrapper;
  display: none;
  
  .menu__item {
    margin: 0 var(--sp-unit);

    &:first-child { margin-left: 0; }
    &:last-child { margin-right: 0; }
  }
  
  .menu__link {
    display: block;
    font-family: $roboto;
    line-height: $line-height-reset;
    font-size: var(--fz-xm);
    padding: var(--sp-half) 0 var(--sp-unit);
    border-bottom: 3px solid $t;
    margin-bottom: -2px;
    
    @include hover {
      text-decoration: $n;
      border-color: var(--cl-secondary);
    }
    
    &.active-trail {
      @include active-trail;
    }
  }
  
  @include media('screen', '>medium') {
    display: flex;
  }
}

.node-type-article #{$publicacoes}, 
.section-tag #{$publicacoes}, 
.section-area #{$publicacoes}, 
.page-publicacoes-edicao .menu__link[href="/edicoes"] { @include active-trail; }
