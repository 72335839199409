///*------------------------------------*\
//    #LAYOUTS - LOGIN
//\*------------------------------------*/

#login-page-from #login-links, 
#login-page-from #register-form { display: $n; }

.o-login {
  &__logo {
    max-width: em(235px);
    margin-bottom: var(--sp-and-half);
  }
  
  &__title {
    text-transform: uppercase;
    color: var(--cl-primary-dark);
    font-size: var(--fz-hl);
    line-height: 1.2;
  }
  
  
  &__header {
    text-align: center;
    margin-bottom: var(--sp-triple);
  }
  
  &__form {
    .item-list { float: right; }
    .form-actions { float: left; }

    .item-list li:first-child { display: $n; }
    .item-list li:last-child { margin-top: em(20px); }
  }
  
  &__footer {
    text-align: center;
    
    hr {
      border-width: 2px;
      border-color: var(--cl-secondary-xlight);
    }
    
    .page-user-register & { display: $n; }
  }
  
  
  &--w .modaal-container { max-width: em(600px); }
}
