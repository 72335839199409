///*------------------------------------*\
//    #LAYOUTS - PAGE PRODUCT
//\*------------------------------------*/

// 
// elements

// o-product-content
.c-product-type { //.field-name-field-arti-type
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  
  &__label {
    overflow: hidden;
    text-indent: -100vw;
    display: block;
    color: var(--cl-primary);
    width: var(--sp-double);
    background-color: var(--cl-primary);
    margin-bottom: 0;
  }
  
  &__item { margin: 0 0 -4px; }
}

.field-name-field-arti-summary {
  p:first-child { margin-top: 0; }
  p:last-child { margin-bottom: 0; }
}

.field-name-field-tags {
  .field-item:not(:last-child)::after {
    content: ',';
    margin-right: var(--sp-half);
  }

  .field-items {
    @include horizontal;
    flex-wrap: wrap;
  }
}
// o-product-content

// o-product-aside-one
.field-name-field-arti-journal {
  .field-label {
    margin: 0 0 var(--sp-half);
    
    &:nth-child(1) { grid-area: label01; }
    &:nth-child(3) { grid-area: label02; }
    &:nth-child(5) { grid-area: label03; }
  }
  
  .field-item {
    color: var(--cl-primary);
    font-size: var(--fz-l);
    font-weight: 700;
    
    &:nth-child(2) { grid-area: item01; }
    &:nth-child(4) { grid-area: item02; }
    &:nth-child(6) { grid-area: item03; }
  }
  
  .field-type-list-text {
    display: grid;
    grid-template-columns: repeat(2, minmax(em(64px), 40%)) minmax(em(64px), 20%);
    grid-column-gap: 4%;
    grid-template-areas: 
      "label01 label02 label03"
      "item01  item02  item03";
    
    &::before, 
    &::after { display: none; }
  }
}

// .field-name-field-evaluation-areas {}
// .field-name-field-knowledge-areas {}

.field-name-field-arti-authors {
  .profile .field { margin-bottom: 0 $i; }

  .profile {
    display: block;
    padding: var(--sp-half) 0;
    border-bottom: 2px solid var(--cl-secondary-light);
    
    &:hover { border-color: var(--cl-secondary); }
  }
  
  .field-label { margin-bottom: 0; }
}

.field-name-field-arti-status {
  color: var(--cl-primary);
  font-size: var(--fz-xxm);
  font-weight: 700;
  padding-left: var(--sp-double);
  background: url('#{$imgPath}/ok-icon-green.svg') 0 center no-repeat;
}

.field-name-field-arti-file {
  .file-icon { display: $n; }
  
  a {
    @extend .c-btn;
    @extend .c-btn--large;
    @extend .c-btn--primary;
    width: em(220px);
    overflow: hidden;
    padding-left: em(12px);
    padding-right: em(12px);
    
    &::before {
      content: 'Ler o Trabalho (PDF)';
      background: url('#{$imgPath}/pdf-icon-brown.svg') 0 center no-repeat;
      padding-left: var(--sp-double);
      margin-right: 100vw;
    }
  }
}

.field-name-field-data-de-envio, 
.field-name-field-arti-publication-date, 
.field-name-field-arti-certification { display: inline-block; }

.field-name-field-data-de-envio, 
.field-name-field-arti-publication-date { margin-right: var(--sp-and-half); }
// o-product-aside-one

// .o-product-aside-two
// .field-name-field-arti-reference {}

.referenciar {
  strong { @include label; }

  p {
    margin: 0;
    padding: var(--sp-unit);
  }
  
  p:first-child { padding-top: 0; }
  
  p:last-child {
    position: relative;
    word-break: break-word;
    background-color: var(--cl-grey-10);

    &::after {
      content: "";
      display: block;
      @include size(50%, 100%);
      background-color: var(--cl-grey-10);
      position: absolute;
      bottom: 0;
      right: -50%;
    }
  }
  
  @include media('screen', '>medium') {
    p { padding: var(--sp-double); }
  }
}
// .o-product-aside-two


// 
// blocks
.o-product-content {
  margin-bottom: var(--sp-triple);

  .field:not(:last-child) { margin-bottom: var(--sp-and-half); }
}

.o-product-aside-one {
  margin-bottom: var(--sp-triple);
  
  .field:not(:last-child) { margin-bottom: var(--sp-and-half); }
  .field.field-name-field-arti-status { margin-bottom: var(--sp-half); }

  @include media('screen', '<=medium') {
    position: relative;
    padding-left: var(--sp-quad);
  }
}

.o-product-aside-two {
  @include media('screen', '<=medium') {
    margin-bottom: var(--sp-double);
  }
}

.node-type-article .o-article { position: relative; }

@include media('screen', '>medium') {
  .node-type-article .o-article {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 48px;
    grid-template-areas: 
    "head    asd-one"
    "read    asd-one"
    "asd-two asd-two";
  }

  .node-type-article .o-main__header { grid-area: head; }
  .o-product-content { grid-area: read; }
  .o-product-aside-one { grid-area: asd-one; }
  .o-product-aside-two { grid-area: asd-two; }
}
