/*------------------------------------*\
  #COMPONENTS - ASIDE HIGHLIGHT
\*------------------------------------*/

.c-aside-highlight {
  @include size(100%, em(200px));
  color: var(--cl-clean);
  background: var(--cl-primary);
  position: relative;
  
  h3 {
    max-width: 35%;
    position: absolute;
    top: var(--sp-and-half);
    left: var(--sp-and-half);
  }
  
  img {
    position: absolute;
    right: -15%;
  }
}
