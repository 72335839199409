/*------------------------------------*\
  #COMPONENTS - SEARCH FORM
\*------------------------------------*/

.c-search-form {
  @include horizontal;
  height: em(50px);
  border: em(2px) solid var(--cl-secondary-light);
  background-color: var(--cl-clean);
  
  .form-text {
    padding: var(--sp-unit) var(--sp-half);
    border: 0;
    background-color: $t;
  }
  
  .form-submit {
    color: $t;
    @include size(em(50px));
    border: 0;
    background: $t url('#{$imgPath}/search-icon.svg') center no-repeat;
    overflow: hidden;
    text-indent: -101%;
  }
  
  .form-item {
    text-transform: $in;
    width: 100%;
    margin-bottom: 0;
  }
  
  .form-actions {
    @include horizontal;
    
    &::before {
      content: '';
      display: inline-block;
      width: em(2px);
      height: var(--sp-double);
      background-color: var(--cl-secondary-light);
    }
  }
}
