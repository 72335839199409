// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Breadcrumb navigation
//
// The path to the current page in the form of a list of links.
//
// Markup: breadcrumb.twig
//
// Style guide: navigation.breadcrumb

//.breadcrumb,
//%breadcrumb {
.breadcrumb {
  margin-bottom: var(--sp-triple);
  
  &__item {
    color: var(--cl-grey-50);
    font-size: var(--fz-s);
    
    &:not(:first-child) { margin-left: var(--sp-half); }
  }

  &__title {
    @include label;
    margin-bottom: var(--sp-quarter);
  }

  &__list { @include horizontal; }
  
  
  @include media('screen', '<=medium') {
    &__item { white-space: nowrap; }
    
    &__list { overflow-x: auto; }
  }
  
  @media print {
    display: none;
  }
}
