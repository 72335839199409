/*------------------------------------*\
  #COMPONENTS - PRODUCT SHARE
\*------------------------------------*/

.share-title {
  grid-area: title;
  
  &__text {
    display: $n;
    @include label;
    line-height: em(47px);
    width: calc(100% - 3rem);
    height: 100%;
    padding-left: var(--sp-unit);
    border-right: 2px solid var(--cl-secondary-light);
  }
  
  &__icon {
    margin: 0 auto;
    fill: var(--cl-grey-50);
  }
  
  @include media('screen', '>medium') {
    &__text { display: block; }
  }
}

.share-in { grid-area: icon-in; }
.share-tw { grid-area: icon-tw; }
.share-fb { grid-area: icon-fb; }


.c-product-share {
  // grid-area: share;
  display: grid;
  
  dd { margin: 0; }
  
  &__link {
    display: flex;
    justify-content: center;
    width: var(--sp-triple);
    height: var(--sp-triple);
    border: 2px solid var(--cl-secondary-light);
    background-color: var(--cl-clean);
    
    @include hover {
      border-color: var(--cl-secondary);
    }
  }
  
  &__title {
    @include horizontal;
    height: var(--sp-triple);
    border: 2px solid var(--cl-secondary-light);
    background-color: var(--cl-clean);
  }
  
  &__item { fill: var(--cl-secondary); }
  
  @include media('screen', '<=medium') {
    grid-template-columns: em(48px);
    grid-template-rows: repeat(3, em(48px)) auto;
    grid-template-areas: 
      "icon-in"
      "icon-tw"
      "icon-fb"
      "title";
    grid-row-gap: em(8px);
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @include media('screen', '>medium') {
    grid-template-columns: repeat(3, em(48px)) auto;
    grid-template-areas: 
      "icon-in icon-tw icon-fb title";
    grid-column-gap: em(8px);
    height: var(--sp-triple);
    margin-bottom: var(--sp-double);
  }
}
