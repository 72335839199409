/*------------------------------------*\
  #COMPONENTS - AVALIATION-QUALIS
\*------------------------------------*/

.block-views-avaliation-area-qualis-block__title {
  color: var(--cl-primary);
  margin-bottom: var(--sp-unit);
}


.c-avaliation-qualis {
  display: flex;
  border-bottom: 1px solid $t;
  padding-bottom: var(--sp-half);
  margin-bottom: var(--sp-half);
  border-color: var(--cl-secondary-light);

  &__title-sub {
    @include label;
    font-style: normal;
    font-weight: 400;
  }

  &__value { color: var(--cl-primary-dark); }

  &__title {
    align-self: center;
    font-family: $roboto;
    font-weight: 400;
    font-size: var(--fz-m);
  }

  &__qualis {
    line-height: $line-height-reset;
    margin-left: auto;
    max-width: 96px;
  }

  &--page {
    
    .c-avaliation-qualis__title {
      margin: 0;
      line-height: 1.5;
      font-size: var(--fz-xm);
    }

    .c-avaliation-qualis__qualis {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
