///*------------------------------------*\
//    #LAYOUTS - HEADER
//\*------------------------------------*/

$w-logo-s: em(100px);
$w-logo-m: em(206px);
$w-logo-l: em(235px);

$col-logo-l: 235px + 32px;

// 
// elements
.c-logo {
  grid-area: logo;
  display: block;
  max-width: $w-logo-m;
  
  // .page-user & {
  //     margin-top: var(--sp-unit);
  //     margin-bottom: var(--sp-double);
  // }
  
  @include media('screen', '>medium') {
    max-width: $in;
    width: $w-logo-l;
    
    // .page-user & {
    //     margin-bottom: var(--sp-triple);
    // }
  }
}


// 
// blocks
.block-search-form {
  grid-area: search;
  max-width: em(650px);
  
  @include media('screen', '>medium') {
    .logged-in & {
      margin-left: var(--sp-double);
      margin-right: var(--sp-double);
    }
  }
}

.c-login-btn { // only anonymous
  grid-area: login;
  
  // @include media('screen', '>medium') {
  //   .page-user & {
  //     height: 100%;
  //       padding-left: var(--sp-quad);
  //       background: var(--cl-secondary-xlight);
  //     background-clip: content-box;
  //   }
  // }
}

.c-cta-enviar-publicacao { grid-area: cta; }

// loggedin
.c-cart-header { grid-area: cart; }
.c-user-menu { grid-area: menu; }


// 
// regions
.o-header {
  margin-top: var(--sp-unit);
  margin-bottom: var(--sp-triple);
  border-bottom: 1px solid var(--cl-secondary-light);
  
  // &__top {}
  
  .front & { margin-bottom: 0; }
  
  // .page-user & {
  //   border: 0;
  //   margin: 0;
  // }
}

.o-header__top {
  //    grid-template-columns: em($col-logo-l) repeat(3, auto); // edge
  //    grid-auto-columns: minmax(auto, em(220px));
  display: grid;
  grid-template-columns: auto em(50px);
  grid-template-areas:
    "logo   logo"
    "search login";
  column-gap: em(12px);
  row-gap: em(12px);
  grid-auto-flow: column;
  align-items: start;
  margin-bottom: var(--sp-and-half);

  .logged-in & {
    grid-template-areas: 
    "logo   logo"
    "search cart";
  }

  // .page-user & { margin-bottom: 0; } // only anonymous

  @include media('screen', '>medium') {
    grid-template-columns: em(235px) auto em(212px) em(122px);
    grid-template-areas:
      "logo search cta login";
    grid-column-gap: em(32px);
    align-items: center;

    .logged-in & {
      grid-template-columns: em(235px) auto em(244px) em(58px) em(210px);
      grid-template-areas: 
      "logo search cta cart menu";
      grid-column-gap: $in;
    }
  }
}


// .page-user .t-btn-login { // only anonymous
//     margin-top: var(--sp-unit);
  
//   @include media('screen', '>medium') {
//       margin-left: var(--sp-double);
//   }
// }

// but page user and user/* not used
.page-user.not-logged-in .o-header__top { // only anonymous
  grid-template-columns: auto em(50px);
  grid-template-areas:
    "logo login";
  grid-column-gap: $in;
  
  @include media('screen', '>medium') {
    grid-template-columns: 3fr 2fr;
  }
}



@include media('screen', '<=medium') {
  .c-logo { margin: auto; }
  
  .c-cta-enviar-publicacao { display: none; }
}

// @include media('screen', '>medium') {}
