// sass-lint:disable no-vendor-prefixes

// Use the saner border-box model for all elements.
* {
  box-sizing: border-box;
}

html,
body {
  // using for safary
  position: relative;
  overflow-x: hidden;
}

html {
  @include define-type-sizing();
  // Prevent adjustments of font size after orientation changes in IE and iOS.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  min-height: 100%;
}

body {
  // Output a horizontal grid to help with debugging typography. The
  // $typey-debug variable will toggle its output.
//  @include typey-debug-grid();
  // Change the default font family in all browsers (opinionated).
  @include typeface(body);
  color: var(--cl-text);
  background-color: var(--cl-clean);
  // Remove default margin and padding.
  margin: 0;
  padding: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  
//  display: grid;
}

article {
  font-weight: 300;
  letter-spacing: em(0.3px);
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
// Upstream patch for normalize.css submitted: https://github.com/necolas/normalize.css/pull/379 - remove this fix once that is merged

[role="button"] { cursor: pointer; }


::-moz-selection {
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
  text-shadow: none;
}

::selection {
  color: var(--cl-clean);
  background-color: var(--cl-secondary);
  text-shadow: none;
}

::placeholder { color: var(--cl-secondary); }
