///*------------------------------------*\
//    #LAYOUTS - FOOTER
//\*------------------------------------*/

.c-back-top {
  grid-column: 3;
  justify-self: end;
  
  display: $n;
  align-items: center;
  text-transform: uppercase;
  font-size: var(--fz-xs);
  padding: 0 var(--sp-quarter);
  border: 1px dotted $t;
  
  @include hover {
    text-decoration: $n;
    border-color: #f0efec;
    border-color: var(--cl-secondary-light);
  }
  
  &__icon {
    fill: var(--cl-clean);
    margin-right: var(--sp-half);
    width: em(12px);
    transform: rotate(-90deg);
  }
  
  @include media('screen', '>medium') {
    display: inline-flex;
  }
}

.c-about {
  margin-bottom: var(--sp-and-half);
  
  &__title {
    font-weight: 400;
    font-size: var(--fz-m);
  }
}

.c-company {
  opacity: .8;
  
  &__address { font-style: normal; }
  
  &__title {
    @include label;
    color: var(--cl-clean);
  }
  
  &__item {
    display: inline-block;
    margin: 0;
    font-size: var(--fz-s);
  }
  
  &__institution { margin-right: var(--sp-unit); }
}

.dev {
  padding: var(--sp-half) var(--sp-half) 0;
  background-color: var(--cl-clean);
  margin-left: auto;
  
  svg { fill: var(--cl-grey-60); }
}


.region-footer {
  margin-bottom: var(--sp-double);
  
  .block__title {
    @include label;
    color: var(--cl-primary);
    margin-bottom: var(--sp-quarter);
  }
  
  .block-menu-menu-footer-about { margin-bottom: var(--sp-unit); }
  
  
  @include media('screen', '>=small') {
    display: flex;
  }

  @include media('screen', '>medium') {
    .menu { @include horizontal; }
    .menu__item:not(:first-child) { margin-left: var(--sp-unit); }
    .block-menu-menu-footer-about { margin-bottom: 0; }
  }
}

.region-footer-top {
  max-width: calc(3 * em(260px));
  grid-column: 2;
}


.o-footer {
  color: var(--cl-clean);
  background-color: var(--cl-grey-60);
  
  a { color: var(--cl-clean); }
  
  &__top {
    padding: var(--sp-and-half) 0;
    background-color: var(--cl-secondary);
  }
  
  &__main { padding: var(--sp-double) 0 var(--sp-triple); }
  
  &__bottom {
    .o-wrapper {
      @include horizontal;
    }
  }
  
  
  // .page-user & {
  //   background-color: $t;
  //     color: var(--cl-grey-50);
  // }
  
  @include media('screen', '>medium') {
    &__top .o-wrapper {
      display: grid;
      grid-template-columns: minmax(em(64px), em(260px)) auto minmax(em(64px), em(260px));
      grid-auto-flow: column;
      align-items: center;
    }
    
    &__top { padding: var(--sp-and-half) 0; }
  }
}
