/*------------------------------------*\
  #COMPONENTS - TITLE PAGE
\*------------------------------------*/

.c-title-page {
  position: relative;
  color: var(--cl-primary-dark);
  background-color: var(--cl-clean);
  box-shadow: -4px 0 0 var(--cl-clean);
  
  &__title {
    text-transform: uppercase;
    line-height: 1.2;
    font-size: var(--fz-hl);
    margin: 0;
    padding: 0;

    .node-type-article & {
      font-family: $roboto;
      font-weight: 300;
    }
  }
  
  &__line {
    display: block;
    @include size(100%, em(2px));
    background-color: $cc;
    position: absolute;
    top: 28px;
    left: -50%;
    z-index: -1;
    
    .node-type-article & { top: 32px; }
  }
}
