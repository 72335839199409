///*----------------------------------*\
//  #UTILITIES - COLORS
//\*----------------------------------*/

// Color Scheme 0.0.2
// Scheme from colors for create classes with properties css, functions for facilitie use in the project like palette, tint, shade, etc
// From DEV Undergrond
// @authors: Jonas Sousa, Allef Silva

// propertie color
//@include make-classes-map($cls-default, $prefix-utility, $radical-classes-color, $propertie-color);
//@include make-classes-map($cls-neutral, $prefix-utility, $radical-classes-color, $propertie-color);
//@include make-classes-map($cls-semantic, $prefix-utility, $radical-classes-color, $propertie-color);
//@include make-classes-map($cls-main, $prefix-utility, $radical-classes-color, $propertie-color);

// propertie background-color
//@include make-classes-map($cls-default, $prefix-utility, $radical-classes-bgc, $propertie-bgc);
//@include make-classes-map($cls-neutral, $prefix-utility, $radical-classes-bgc, $propertie-bgc);
//@include make-classes-map($cls-semantic, $prefix-utility, $radical-classes-bgc, $propertie-bgc);
//@include make-classes-map($cls-main, $prefix-utility, $radical-classes-bgc, $propertie-bgc);

// 
// SHAME
// 

// prop - color
.u-cl-white { color: var(--cl-clean); }
.u-cl-black { color: var(--cl-dark); }
.u-cl-text { color: var(--cl-text); }
.u-cl-link-visited { color: var(--cl-link-visited); }

.u-cl-grey-10 { color: var(--cl-grey-10); }
.u-cl-grey-20 { color: var(--cl-grey-20); }
.u-cl-grey-30 { color: var(--cl-grey-30); }
.u-cl-grey-40 { color: var(--cl-grey-40); }
.u-cl-grey-50 { color: var(--cl-grey-50); }
.u-cl-grey-60 { color: var(--cl-grey-60); }
.u-cl-grey-70 { color: var(--cl-grey-70); }
.u-cl-grey-80 { color: var(--cl-grey-80); }
.u-cl-grey-90 { color: var(--cl-grey-90); }

.u-cl-sucess { color: var(--cl-sucess); }
.u-cl-info { color: var(--cl-info); }
.u-cl-warning { color: var(--cl-warning); }
.u-cl-error { color: var(--cl-error); }

.u-cl-primary { color: var(--cl-primary); }
.u-cl-secondary { color: var(--cl-secondary); }
.u-cl-tertiary { color: var(--cl-tertiary); }

.u-cl-primary-dark { color: var(--cl-primary-dark); }

// 
// prop - background-color
.u-bgc-white { background-color: var(--cl-clean); }
.u-bgc-black { background-color: var(--cl-dark); }
.u-bgc-text { background-color: var(--cl-text); }
.u-bgc-link-visited { background-color: var(--cl-link-visited); }

.u-bgc-grey-10 { background-color: var(--cl-grey-10); }
.u-bgc-grey-20 { background-color: var(--cl-grey-20); }
.u-bgc-grey-30 { background-color: var(--cl-grey-30); }
.u-bgc-grey-40 { background-color: var(--cl-grey-40); }
.u-bgc-grey-50 { background-color: var(--cl-grey-50); }
.u-bgc-grey-60 { background-color: var(--cl-grey-60); }
.u-bgc-grey-70 { background-color: var(--cl-grey-70); }
.u-bgc-grey-80 { background-color: var(--cl-grey-80); }
.u-bgc-grey-90 { background-color: var(--cl-grey-90); }

.u-bgc-sucess { background-color: var(--cl-sucess); }
.u-bgc-info { background-color: var(--cl-info); }
.u-bgc-warning { background-color: var(--cl-warning); }
.u-bgc-error { background-color: var(--cl-error); }

.u-bgc-primary { background-color: var(--cl-primary); }
.u-bgc-secondary { background-color: var(--cl-secondary); }
.u-bgc-tertiary { background-color: var(--cl-tertiary); }

.u-bgc-primary-dark { background-color: var(--cl-primary-dark); }
