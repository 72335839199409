/*------------------------------------*\
  #COMPONENTS - PHRASE HIGHLIGHT
\*------------------------------------*/

.c-phrase-highlight {
  font-size: em(28px);
  font: 300 var(--fz-xl)/1.2 $roboto;
  color: var(--cl-primary-dark);
  
  @include media('screen', '>medium') {
    font-size: var(--fz-hl);
  }
}
