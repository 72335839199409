///*------------------------------------*\
//    #LAYOUTS - CTA BLOCK
//\*------------------------------------*/

.o-cta-call-header {
  text-align: center;
  
  h4 {
    display: inline-block;
    line-height: $line-height-reset;
    color: var(--cl-primary);
    font-size: var(--fz-hl);
    padding: 0 var(--sp-unit);
    background-color: var(--cl-clean);
  }
  
  em {
    display: block;
    font-style: normal;
    font-weight: 300;
    color: var(--cl-grey-50);
    font-size: var(--fz-xm);
    margin-bottom: em(12px);
  }
  
  @include media('screen', '>medium') {
    margin-bottom: -53px;
    position: relative;
    z-index: 5;
    
    h4 { margin-bottom: em(12px); }
    
    em {
      font-size: var(--fz-l);
      margin-bottom: 0;
    }
  }
}

.o-cta-call-footer {
  display: table;
  margin: -(em(27px)) auto 0;
  text-align: center;
  position: relative;

  &__item {
    display: inline-block;
    padding: 0 var(--sp-unit);
    margin-bottom: var(--sp-half);
    background: var(--cl-clean);
  }
  
  &__txt {
    display: block;
    font-size: var(--fz-s);
    line-height: $line-height-text;
    max-width: em(280px);
  }
}


.o-cta-call-user, 
.o-cta-call {
  h5 {
    color: var(--cl-tertiary);
    font-weight: 300;
    line-height: $line-height-reset;
  }
  
  li::before {
    counter-increment: term;
    content: "0" counter(term);
    font-weight: 700;
  }
  
  ol { counter-reset: term; }
}

.o-cta-call {
  overflow-x: auto;
  border: 2px solid var(--cl-secondary-light);
  
  h5 {
    font-size: var(--fz-hl);
    margin-top: - em(18px);
  }
  
  ol {
    display: grid;
    grid-template-columns: repeat(3, minmax(280px, 1fr));
    grid-column-gap: em(16px);
    padding: var(--sp-double) var(--sp-unit);
  }
  
  li::before {
    color: var(--cl-secondary-light);
    font-size: var(--fz-ul);
    background-color: var(--cl-clean);
  }
  
  @include media('screen', '>medium') {
    ol {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: em(32px);
      padding: var(--sp-triple) var(--sp-double);
      margin-top: var(--sp-double);
    }
  }
}
